// useUserRoles.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const useUserRoles = () => {
  const [userRoles, setUserRoles] = useState([]);
  const [isRolesLoading, setIsRolesLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('User is not authenticated');
        setIsRolesLoading(false);
        return;
      }

      try {
        const token = await currentUser.getIdToken(true);

        const response = await axios.get(
          'https://project-download-upload.wn.r.appspot.com/user-roles',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { email: currentUser.email },
          }
        );

        if (response.data && response.data.roles) {
          setUserRoles(response.data.roles);
        } else {
          setError('Invalid response from server');
        }
      } catch (err) {
        console.error('Error fetching user roles:', err);
        // Provide more detailed error messages based on response status
        if (err.response) {
          // Server responded with a status other than 2xx
          setError(`Error ${err.response.status}: ${err.response.data.message || 'Failed to fetch roles.'}`);
        } else if (err.request) {
          // Request was made but no response received
          setError('No response from server. Please check your network connection.');
        } else {
          // Something else happened
          setError('An unexpected error occurred.');
        }
      } finally {
        setIsRolesLoading(false);
      }
    };

    fetchRoles();
  }, []);

  return { userRoles, isRolesLoading, error };
};

export default useUserRoles;
