// ./src/components/AccountManagement/DefaultSettings/defaultSettingsOverlay.js

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance';
import './defaultSettings.css';

const DefaultSettingsOverlay = ({
  settingKey,
  scope,         // "global", "property", or "game"
  contextId,     // property_name or game_name
  properties,
  games,
  defaults,
  onClose,
  onUpdateDefaults,
}) => {
  const [pin, setPin] = useState('');
  const [template, setTemplate] = useState('');
  const [screenText, setScreenText] = useState(''); // NEW state
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const [selectedGameName, setSelectedGameName] = useState('');

  // Pre-select property/game if user clicked "Change"
  useEffect(() => {
    if (scope === 'property' && contextId) {
      setSelectedPropertyId(contextId);
    }
    if (scope === 'game' && contextId) {
      setSelectedGameName(contextId);
    }
  }, [scope, contextId]);

  // Pre-populate existing data, including screenText (flattened)
  useEffect(() => {
    if (!defaults) return;

    let existingVal = null;
    if (scope === 'global') {
      existingVal = defaults.global[settingKey];
    } else if (scope === 'property' && contextId && defaults.property[contextId]) {
      existingVal = defaults.property[contextId][settingKey];
    } else if (scope === 'game' && contextId && defaults.game[contextId]) {
      existingVal = defaults.game[contextId][settingKey];
    }

    if (!existingVal) return;

    // If we found a value, handle each setting specifically
    if (settingKey === 'screenTemplate') {
      setTemplate(existingVal);
    } else if (settingKey === 'screenPin') {
      // We do not prefill hashed pins
    } else if (settingKey === 'screenText') {
      // existingVal is pipe‐delimited, convert to multiline
      const multiline = existingVal.split('|').join('\n');
      setScreenText(multiline);
    }
  }, [defaults, scope, settingKey, contextId]);

  // Scope-specific dropdown
  const renderScopeDropdown = () => {
    if (scope === 'property') {
      return (
        <div className="ds-input-group">
          <label htmlFor="propertySelect">Select Property</label>
          <select
            id="propertySelect"
            name="propertySelect"
            value={selectedPropertyId}
            onChange={(e) => setSelectedPropertyId(e.target.value)}
            required
          >
            <option value="" disabled>-- Select a Property --</option>
            {properties.map((prop) => (
              <option key={prop.property_name} value={prop.property_name}>
                {prop.property_name}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (scope === 'game') {
      return (
        <div className="ds-input-group">
          <label htmlFor="gameSelect">Select Game</label>
          <select
            id="gameSelect"
            name="gameSelect"
            value={selectedGameName}
            onChange={(e) => setSelectedGameName(e.target.value)}
            required
          >
            <option value="" disabled>-- Select a Game --</option>
            {games.map((gm) => (
              <option key={gm.game_name} value={gm.game_name}>
                {gm.game_name}
              </option>
            ))}
          </select>
        </div>
      );
    }

    // Global => no dropdown
    return null;
  };

  // Setting-specific form fields
  const renderSettingFields = () => {
    switch (settingKey) {
      case 'screenTemplate':
        return (
          <div className="ds-template-selection">
            <div
              className={`ds-template-item ${template === 'Template1' ? 'selected' : ''}`}
              onClick={() => setTemplate('Template1')}
            >
              <img src="/templates/template1.png" alt="Template 1" />
              <div className="ds-template-name">Template 1</div>
            </div>
            <div
              className={`ds-template-item ${template === 'Template2' ? 'selected' : ''}`}
              onClick={() => setTemplate('Template2')}
            >
              <img src="/templates/template2.png" alt="Template 2" />
              <div className="ds-template-name">Template 2</div>
            </div>
            <div
              className={`ds-template-item ${template === 'Template3' ? 'selected' : ''}`}
              onClick={() => setTemplate('Template3')}
            >
              <img src="/templates/template3.png" alt="Template 3" />
              <div className="ds-template-name">Template 3</div>
            </div>
          </div>
        );

      case 'screenPin':
        return (
          <div className="ds-input-group">
            <label htmlFor="screenPinInput">Screen Pin</label>
            <input
              id="screenPinInput"
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="4-6 digits"
              required
              pattern="\d{4,6}"
              title="Enter a 4 to 6-digit numeric pin."
            />
          </div>
        );

      case 'screenText': // NEW multiline text case
        return (
          <div className="ds-input-group">
            <label htmlFor="screenTextArea">Screen Text (multi‐line)</label>
            <textarea
              id="screenTextArea"
              value={screenText}
              onChange={(e) => setScreenText(e.target.value)}
              rows={6}
              placeholder="Enter each line of text (will be stored in defaults.yaml as pipe‐delimited)."
            />
          </div>
        );

      default:
        return null;
    }
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate property or game selection
    if (scope === 'property' && !selectedPropertyId) {
      toast.error('Please select a property.');
      return;
    }
    if (scope === 'game' && !selectedGameName) {
      toast.error('Please select a game.');
      return;
    }

    // Build payload
    const payload = {
      action: 'add',
      scope: scope,
      settingKey: settingKey,
    };

    // property_name or game_name if needed
    if (scope === 'property') {
      payload.property_name = selectedPropertyId;
    }
    if (scope === 'game') {
      payload.game_name = selectedGameName;
    }

    // Flatten data
    if (settingKey === 'screenTemplate' && template) {
      payload.template = template;
    }
    if (settingKey === 'screenPin' && pin) {
      payload.screenPin = pin;
    }
    if (settingKey === 'screenText') {
      // Convert multiline => pipe‐delimited
      const pipeString = screenText.split('\n').join('|');
      payload.screenText = pipeString;
    }

    try {
      const response = await axiosInstance.post('/api/setDefaults', payload);
      toast.success('Setting updated successfully');

      // Update local defaults
      onUpdateDefaults(response.data.data);

      onClose();
    } catch (error) {
      console.error('Error updating setting:', error);
      toast.error('Failed to update setting');
    }
  };

  return (
    <div className="ds-overlay">
      <div className="ds-overlay-content">
        <button
          className="ds-close-button"
          onClick={onClose}
          aria-label="Close Overlay"
        >
          &times;
        </button>

        <form className="ds-settings-form" onSubmit={handleSubmit}>
          <h2>
            {`Set ${
              scope === 'global'
                ? 'Global'
                : scope.charAt(0).toUpperCase() + scope.slice(1)
            } - ${settingKey}`}
          </h2>

          {/* Scope dropdown if needed */}
          {renderScopeDropdown()}

          {/* Render relevant fields for the setting */}
          {renderSettingFields()}

          <div className="ds-submit-button">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DefaultSettingsOverlay;
