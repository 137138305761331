// Dashboard.js
import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInstance'; // Adjust the path if necessary
import './dashboard.css';

const Dashboard = () => {
  // State for Device Monitoring
  const [deviceStats, setDeviceStats] = useState({
    totalDevices: 0,
    devicesOnline: 0,
    devicesOffline: 0,
  });
  const [deviceLoading, setDeviceLoading] = useState(true);
  const [deviceError, setDeviceError] = useState(null);

  // State for User Management (All Users)
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);

  // State for Device Support Tickets
  const [ticketStats, setTicketStats] = useState({
    openTickets: 0,
    closedTickets: 0,
  });
  const [ticketLoading, setTicketLoading] = useState(true);
  const [ticketError, setTicketError] = useState(null);

  // State for Media Info
  const [mediaSummary, setMediaSummary] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [mediaError, setMediaError] = useState(null);

  // Fetch Device Monitoring Data
  const fetchDeviceStats = async () => {
    try {
      const response = await axiosInstance.get('/api/deviceStats'); // Adjust endpoint as needed
      setDeviceStats(response.data);
    } catch (error) {
      console.error('Error fetching device stats:', error);
      setDeviceError('Failed to load device statistics.');
    } finally {
      setDeviceLoading(false);
    }
  };

  // Fetch All Users Data from /api/allUsers
  const fetchAllUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/allUsers'); // Backend API endpoint
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching all users:', error);
      setUsersError('Failed to load user data.');
    } finally {
      setUsersLoading(false);
    }
  };

  // Fetch Device Support Tickets Data
  const fetchTicketStats = async () => {
    try {
      const response = await axiosInstance.get('/api/tickets/stats'); // Adjust endpoint as needed
      setTicketStats({
        openTickets: response.data.openTickets,
        closedTickets: response.data.closedTickets,
      });
    } catch (error) {
      console.error('Error fetching ticket stats:', error);
      setTicketError('Failed to load ticket statistics.');
    } finally {
      setTicketLoading(false);
    }
  };

  // Fetch Media Info Data
  const fetchMediaSummary = async () => {
    try {
      const response = await axiosInstance.get('/api/mediaInfo/stats'); // Adjust endpoint as needed
      setMediaSummary(response.data);
    } catch (error) {
      console.error('Error fetching media summary:', error);
      setMediaError('Failed to load media statistics.');
    } finally {
      setMediaLoading(false);
    }
  };

  // Fetch all data on component mount
  useEffect(() => {
    fetchDeviceStats();
    fetchAllUsers(); // Fetch all users data
    fetchTicketStats();
    fetchMediaSummary();
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="dashboard-cards">
        {/* Device Monitoring Card */}
        <div className="dashboard-card">
          <h2>Device Monitoring</h2>
          {deviceLoading ? (
            <p>Loading...</p>
          ) : deviceError ? (
            <p className="error">{deviceError}</p>
          ) : (
            <div className="card-content">
              <p><strong>Total Devices:</strong> {deviceStats.totalDevices}</p>
              <p><strong>Devices Online:</strong> {deviceStats.devicesOnline}</p>
              <p><strong>Devices Offline:</strong> {deviceStats.devicesOffline}</p>
            </div>
          )}
        </div>

        {/* User Management Card */}
        <div className="dashboard-card">
          <h2>User Management</h2>
          {usersLoading ? (
            <p>Loading...</p>
          ) : usersError ? (
            <p className="error">{usersError}</p>
          ) : users.length > 0 ? (
            <UsersTable users={users} />
          ) : (
            <p>No user data available.</p>
          )}
        </div>

        {/* Device Support Tickets Card */}
        <div className="dashboard-card">
          <h2>Support Tickets</h2>
          {ticketLoading ? (
            <p>Loading...</p>
          ) : ticketError ? (
            <p className="error">{ticketError}</p>
          ) : (
            <div className="card-content">
              <p><strong>Open Tickets:</strong> {ticketStats.openTickets}</p>
              <p><strong>Closed Tickets:</strong> {ticketStats.closedTickets}</p>
            </div>
          )}
        </div>

        {/* Media Info Card */}
        <div className="dashboard-card">
          <h2>Media Information</h2>
          {mediaLoading ? (
            <p>Loading...</p>
          ) : mediaError ? (
            <p className="error">{mediaError}</p>
          ) : mediaSummary ? (
            <div className="card-content">
              <p><strong>Total Media:</strong> {mediaSummary.totalMedia}</p>
              <p><strong>Images:</strong> {mediaSummary.typeBreakdown.Images}</p>
              <p><strong>Videos:</strong> {mediaSummary.typeBreakdown.Videos}</p>
              <p><strong>Total Storage:</strong> {(mediaSummary.totalStorage / 1e6).toFixed(2)} MB</p>
              <p><strong>Storage Images:</strong> {(mediaSummary.storageBreakdown.Images / 1e6).toFixed(2)} MB</p>
              <p><strong>Storage Videos:</strong> {(mediaSummary.storageBreakdown.Videos / 1e6).toFixed(2)} MB</p>
            </div>
          ) : (
            <p>No media data available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * UsersTable Component
 * Displays a table of users with search and pagination functionalities.
 */
const UsersTable = ({ users }) => {
  // State for Search
  const [searchTerm, setSearchTerm] = useState('');

  // State for Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10; // Adjust as needed

  // Handle Search Filtering
  const filteredUsers = users.filter((user) =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  // Handle Page Change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="users-table-container">
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search users..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {filteredUsers.length > 0 ? (
        <>
          {/* Users Table */}
          <table className="users-table">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Roles</th>
                <th>Company Name</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user) => (
                <tr key={user.user_id}>
                  <td>{user.user_id}</td>
                  <td>{user.username}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.user_roles.join(', ')}</td>
                  <td>{user.company_name}</td>
                  <td>{new Date(user.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (page) => (
                <button
                  key={page}
                  className={`page-button ${
                    currentPage === page ? 'active' : ''
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              )
            )}
          </div>
        </>
      ) : (
        <p>No user data available.</p>
      )}
    </div>
  );
};

export default Dashboard;
