// App.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import Login from './Login';
import MediaManagement from './MediaManagement';
import AccountManagement from './AccountManagement';
import DeviceManagement from './DeviceManagement';
import Dashboard from './dashboard'; // Import the Dashboard component
import './App.css';
import './fontAwesome';

const App = () => {
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    setDropdownOpen(false);
    window.location.href = '/';
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getUserInitial = (email) => {
    return email ? email.charAt(0).toUpperCase() : '';
  };

  return (
    <Router>
      <div>
        {user ? (
          <div>
            <header className="header">
              <nav>
                <ul>
                  <li>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/media-management">Media Management</Link>
                  </li>
                  <li>
                    <Link to="/account-management">Account Management</Link>
                  </li>
                  <li>
                    <Link to="/device-management">Device Management</Link>
                  </li>
                </ul>
              </nav>
              <div className="user-dropdown" onClick={toggleDropdown}>
                <div className="user-icon">{getUserInitial(user.email)}</div>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <span className="username">{user.email}</span>
                    <button onClick={handleLogout}>Logout</button>
                  </div>
                )}
              </div>
            </header>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} /> {/* Use the Dashboard component */}
              <Route path="/" element={<Navigate to="/dashboard" />} /> {/* Redirect to Dashboard */}
              <Route path="/media-management" element={<MediaManagement />} />
              <Route path="/account-management" element={<AccountManagement />} />
              <Route path="/device-management" element={<DeviceManagement />} />
            </Routes>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
