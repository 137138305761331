import React, { useState } from 'react';
import './companyProfile.css'; // Only contains styling specific to the CompanyProfile
import DefaultSettings from './DefaultSettings/defaultSettings'; // <-- New import
import CompanyProfileSection from './CompanyProfile/companyProfile';

const CompanyProfile = () => {
  const [activeTab, setActiveTab] = useState('companyProfile');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="cp-container">
      {/* Tabs */}
      <div className="cp-tabs">
        <button
          onClick={() => handleTabChange('companyProfile')}
          className={activeTab === 'companyProfile' ? 'active' : ''}
        >
          Company Profile
        </button>
        <button
          onClick={() => handleTabChange('defaultSettings')}
          className={activeTab === 'defaultSettings' ? 'active' : ''}
        >
          Default Settings
        </button>
      </div>

      {/* Tab Content */}
      <div className="cp-tab-content">
        {activeTab === 'companyProfile' && <CompanyProfileSection />}
        {activeTab === 'defaultSettings' && <DefaultSettings />}
      </div>
    </div>
  );
};

export default CompanyProfile;
