// src/components/ManageMedia.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './manageMedia.css';
import UploadMedia from './UploadMedia';
import previewIcon from '../content/previewIcon.svg';
import listIcon from '../content/listIcon.svg';
import directoryIcon from '../content/directoryIcon.svg';
import emptyDirectoryIcon from '../content/emptyDirectoryIcon.svg';
import useUserRoles from '../hooks/useUserRoles'; // Import the custom hook

const ItemType = 'MEDIA';

/**
 * ManageMedia Component
 * Handles the display and management of media files with RBAC integration.
 */
const ManageMedia = ({ setSubHeader }) => {
  // State variables
  const [mediaFiles, setMediaFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploadOverlayVisible, setIsUploadOverlayVisible] = useState(false);
  const [activeButton, setActiveButton] = useState('image');
  const [currentIcon, setCurrentIcon] = useState(previewIcon);
  const [bucketName, setBucketName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [directories, setDirectories] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);

  // RBAC-related states from the custom hook
  const { userRoles, isRolesLoading, error: rolesError } = useUserRoles();

  // States for selected media and Edit form
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [isEditOverlayVisible, setIsEditOverlayVisible] = useState(false);
  const [editTags, setEditTags] = useState([]);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    setSubHeader('Manage Media');
    if (currentUser) {
      fetchCustomerData();
    } else {
      console.error('User is not authenticated');
    }
  }, [setSubHeader, currentUser]);

  /**
   * Fetches customer ID and bucket name based on the authenticated user.
   */
  const fetchCustomerData = async () => {
    try {
      const token = await currentUser.getIdToken(true);
      const customerResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerLookup',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const cId = customerResponse.data.customer_id;
      setCustomerId(cId);

      const bucketResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerUID',
        {
          params: { customer_id: cId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const customerUID = bucketResponse.data.customer_uid;
      setBucketName(customerUID);

      // Fetch directories and media files
      fetchDirectories(cId);
      // By default, load images
      fetchMediaFiles(null, 'image');
    } catch (error) {
      console.error('Error fetching customer ID and bucket name:', error);
    }
  };

  /**
   * Fetches directories based on the customer ID.
   */
  const fetchDirectories = async (cId) => {
    try {
      const token = await currentUser.getIdToken(true);

      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/media/fetchDirectories',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { customer_id: cId },
        }
      );

      const directoriesData = response.data.directories;
      setDirectories(directoriesData);
    } catch (error) {
      console.error('Error fetching directories:', error);
    }
  };

  /**
   * Fetches media files from the backend by type (image or video)
   * and optional folder path (folderTag). Then requests a signed URL
   * for each file.
   *
   * @param {string|null} folderPath - optional folderTag
   * @param {string|null} forcedType - override type ("image" or "video"); if null, we use activeButton
   */
  const fetchMediaFiles = async (folderPath = null, forcedType = null) => {
    setIsLoading(true);
    try {
      const token = await currentUser.getIdToken(true);

      // Use forcedType if provided; otherwise, use activeButton
      const usedType = forcedType || activeButton;

      const params = { type: usedType };
      if (folderPath) {
        params.folder = folderPath;
      }

      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/media',
        {
          headers: { Authorization: `Bearer ${token}` },
          params,
        }
      );

      const mediaData = response.data; // e.g., ["myFile.jpg", "myVideo.mp4", ...]
      const mediaItems = [];

      for (const mediaFileName of mediaData) {
        // Ensure that the file name includes "Media/" if not already present
        const finalFilePath = mediaFileName.startsWith('Media/')
          ? mediaFileName
          : `Media/${mediaFileName}`;

        console.log('Requesting signed URL for fileName:', finalFilePath);

        const signedUrlResponse = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/imagePreview-signed-url',
          { fileName: finalFilePath },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        mediaItems.push({ fileName: finalFilePath, url: signedUrlResponse.data.signedUrl });
      }

      setMediaFiles(mediaItems);
    } catch (error) {
      console.error('Error fetching media files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Moves (tags) a media file with a folderTag metadata.
   * Once done, refresh the current view of media files.
   */
  const moveMediaToFolder = async (fileUrl, folderTag) => {
    try {
      if (!fileUrl) {
        throw new Error('fileUrl is undefined or null.');
      }

      console.log('moveMediaToFolder called with:', { fileUrl, folderTag });

      const token = await currentUser.getIdToken(true);
      console.log('Token retrieved successfully:', token);

      // Extract the file path from the signed URL (before the query params),
      // removing the "https://storage.googleapis.com/<bucketName>/"
      const fileName = decodeURIComponent(
        fileUrl
          .split('?')[0]
          .replace(`https://storage.googleapis.com/${bucketName}/`, '')
      );
      console.log('Extracted file name:', fileName);

      console.log('Preparing to tag file with folderTag:', folderTag);

      // Send request to tagMediaFile API
      const response = await axios.post(
        'https://project-download-upload.wn.r.appspot.com/api/tagMediaFile',
        {
          fileName,
          folderTag,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log('File tagged successfully:', response.data);

      // Refresh the media files after tagging
      fetchMediaFiles(selectedFolder, activeButton);
      alert('File tagged successfully');
    } catch (error) {
      console.error('Error tagging file:', {
        message: error.message,
        stack: error.stack,
        config: error.config,
        response: error.response ? error.response.data : 'No response data',
      });
      alert('Failed to tag file. Please try again.');
    }
  };

  // Reorders media items in the local state (drag-and-drop)
  const moveMedia = (dragIndex, hoverIndex) => {
    const draggedMedia = mediaFiles[dragIndex];
    const updatedMediaFiles = [...mediaFiles];
    updatedMediaFiles.splice(dragIndex, 1);
    updatedMediaFiles.splice(hoverIndex, 0, draggedMedia);
    setMediaFiles(updatedMediaFiles);
  };

  /**
   * Renders the grid/list of media items, applying the searchTerm filter.
   */
  const renderMediaFiles = () => {
    if (mediaFiles.length === 0) {
      return <div className="manageMedia-no-media">No media files found</div>;
    }
    return mediaFiles
      .filter((item) => item.fileName.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((item, index) => (
        <DraggableMedia
          key={item.fileName}
          fileName={item.fileName}
          fileUrl={item.url}
          index={index}
          moveMedia={moveMedia}
          moveMediaToFolder={moveMediaToFolder}
          activeButton={activeButton}
          directories={directories}
          selectedMediaFile={selectedMediaFile}
          setSelectedMediaFile={setSelectedMediaFile}
        />
      ));
  };

  /**
   * Handlers for top buttons (Images / Videos).
   * We explicitly pass the forced type to fetchMediaFiles().
   */
  const handleImagesClick = () => {
    setActiveButton('image');
    setSelectedFolder(null);
    setSelectedMediaFile(null);
    setIsEditOverlayVisible(false);

    fetchDirectories(customerId);
    // Pass "image" directly
    fetchMediaFiles(null, 'image');
  };

  const handleVideosClick = () => {
    setActiveButton('video');
    setSelectedFolder(null);
    setSelectedMediaFile(null);
    setIsEditOverlayVisible(false);

    fetchDirectories(customerId);
    // Pass "video" directly
    fetchMediaFiles(null, 'video');
  };

  /**
   * Upload Overlay controls
   */
  const handleUploadClick = () => {
    setIsUploadOverlayVisible(true);
    setIsEditOverlayVisible(false);
  };

  const handleOverlayClose = () => {
    setIsUploadOverlayVisible(false);
  };

  /**
   * Switches between preview icon and list icon
   */
  const handleIconClick = () => {
    setCurrentIcon(currentIcon === previewIcon ? listIcon : previewIcon);
  };

  /**
   * Clicking on a folder in the UI
   */
  const handleFolderClick = (folder) => {
    const newSelectedFolder = selectedFolder ? `${selectedFolder}/${folder}` : folder;
    setSelectedFolder(newSelectedFolder);
    setSelectedMediaFile(null);
    setIsEditOverlayVisible(false);

    fetchDirectories(customerId);
    fetchMediaFiles(newSelectedFolder, activeButton);
  };

  /**
   * Adds a new folder (folderTag) in the system
   */
  const handleAddFolderClick = async () => {
    const folderName = prompt('Enter new folder name:');
    if (folderName && folderName.trim() !== '') {
      try {
        setIsLoading(true);
        const token = await currentUser.getIdToken(true);

        // Fetch customer_id
        const customerIdResponse = await axios.get(
          'https://project-download-upload.wn.r.appspot.com/api/customerID',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const cId = customerIdResponse.data.customer_id;

        // Call the addDirectoryTag API
        await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/media/addDirectory',
          {
            newDirectoryTag: folderName,
            customerId: cId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Re-fetch directories after adding
        fetchDirectories(cId);
      } catch (error) {
        console.error('Error adding folder tag:', error);
        alert('Failed to add folder tag. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Conditionally renders the folder dropdown at the "home" level
   */
  const renderFolderDropdown = () => {
    if (selectedFolder === null) {
      return (
        <div className="manageMedia-folder-dropdown">
          <div
            className="manageMedia-folder-dropdown-header"
            onClick={() => setIsFolderDropdownOpen(!isFolderDropdownOpen)}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsFolderDropdownOpen(!isFolderDropdownOpen);
              }
            }}
          >
            <span className="manageMedia-folder-dropdown-title">Folder</span>
            <span
              className={`manageMedia-folder-dropdown-icon ${
                isFolderDropdownOpen ? 'open' : ''
              }`}
            >
              {isFolderDropdownOpen ? '▲' : '▼'}
            </span>
          </div>
          {isFolderDropdownOpen && (
            <div className="manageMedia-folder-dropdown-content">{renderFolders()}</div>
          )}
        </div>
      );
    }
    return null; // Do not render the dropdown at other levels
  };

  /**
   * Breadcrumb navigation for subfolders
   */
  const handleBreadcrumbClick = (folderPath) => {
    setSelectedFolder(folderPath);
    setSelectedMediaFile(null);
    setIsEditOverlayVisible(false);
    fetchDirectories(customerId);
    fetchMediaFiles(folderPath, activeButton);
  };

  const renderBreadcrumbs = () => {
    if (!selectedFolder) {
      return null;
    }

    const pathSegments = selectedFolder.split('/').filter(Boolean);

    return (
      <div className="manageMedia-breadcrumbs">
        <button className="manageMedia-breadcrumb" onClick={() => handleBreadcrumbClick(null)}>
          Home
        </button>
        {pathSegments.map((segment, index) => {
          const pathUpToSegment = pathSegments.slice(0, index + 1).join('/');
          return (
            <React.Fragment key={index}>
              <span className="manageMedia-breadcrumb-separator"> / </span>
              <button
                className="manageMedia-breadcrumb"
                onClick={() => handleBreadcrumbClick(pathUpToSegment)}
              >
                {segment}
              </button>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  /**
   * Renders all folders (DroppableFolder) + "Add Folder" at the home level
   */
  const renderFolders = () => {
    return (
      <div className="manageMedia-folder-list">
        {directories.map((folder, index) => (
          <DroppableFolder
            key={index}
            folder={folder}
            moveMediaToFolder={moveMediaToFolder}
            handleFolderClick={handleFolderClick}
            isLoading={isLoading}
          />
        ))}

        {selectedFolder === null && (
          <div
            className="manageMedia-folder-icon-container"
            onClick={handleAddFolderClick}
            style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
          >
            <img
              src={emptyDirectoryIcon}
              alt="Add Folder Icon"
              className="manageMedia-folder-icon"
            />
            <span className="manageMedia-folder-name">Add Folder</span>
          </div>
        )}
      </div>
    );
  };

  /**
   * Delete button handler
   */
  const handleDeleteClick = async () => {
    if (selectedMediaFile) {
      const confirmDelete = window.confirm('Are you sure you want to delete this media file?');
      if (confirmDelete) {
        try {
          const token = await currentUser.getIdToken(true);

          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/deleteMediaFile',
            {
              fileName: selectedMediaFile.fileName,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          alert('Media file deleted successfully');

          // Remove it from state
          setMediaFiles((prev) =>
            prev.filter((item) => item.fileName !== selectedMediaFile.fileName)
          );

          setSelectedMediaFile(null);
        } catch (error) {
          console.error('Error deleting media file:', error);
          alert('Failed to delete media file. Please try again.');
        }
      }
    }
  };

  /**
   * Edit button handler
   */
  const handleEditClick = async () => {
    if (selectedMediaFile) {
      try {
        const token = await currentUser.getIdToken(true);

        const response = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/getMediaTags',
          { fileName: selectedMediaFile.fileName },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const tags = response.data.tags || {};
        console.log('Fetched tags:', tags);

        const tagsArray = Object.entries(tags).map(([key, value]) => ({ key, value }));

        setEditTags(tagsArray.length > 0 ? tagsArray : [{ key: '', value: '' }]);
        setIsEditOverlayVisible(true);
      } catch (error) {
        console.error('Error fetching media tags:', error);
        alert('Failed to fetch media tags. Please try again.');
      }
    }
  };

  /**
   * Edit Form Handlers
   */
  const handleEditTagChange = (index, field, value) => {
    const updatedTags = [...editTags];
    updatedTags[index][field] = value;
    setEditTags(updatedTags);
  };

  const handleAddEditTag = () => {
    if (editTags.length < 10) {
      setEditTags([...editTags, { key: '', value: '' }]);
    }
  };

  const handleRemoveEditTag = (index) => {
    const updatedTags = [...editTags];
    updatedTags.splice(index, 1);
    setEditTags(updatedTags);
  };

  const handleEditCancel = () => {
    setIsEditOverlayVisible(false);
    setEditTags([]);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await currentUser.getIdToken(true);

      // Convert editTags array to object
      const metadata = {};
      editTags.forEach((tag) => {
        if (tag.key && tag.value) {
          metadata[tag.key] = tag.value;
        }
      });

      await axios.post(
        'https://project-download-upload.wn.r.appspot.com/api/updateMediaMetadata',
        {
          fileName: selectedMediaFile.fileName,
          metadata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      alert('Media tags updated successfully');

      // Reset form
      setIsEditOverlayVisible(false);
      setEditTags([]);
    } catch (error) {
      console.error('Error updating media tags:', error);
      alert('Failed to update media tags. Please try again.');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="manageMedia-content-manager-container">
        <div className="manageMedia-content-manager">
          {/* Top Buttons: Images / Videos, Search, Upload, Edit/Delete, View Mode */}
          <div className="manageMedia-content-manager-buttons">
            <button
              onClick={handleImagesClick}
              className={activeButton === 'image' ? 'active' : ''}
              disabled={isLoading}
            >
              Images
            </button>
            <button
              onClick={handleVideosClick}
              className={activeButton === 'video' ? 'active' : ''}
              disabled={isLoading}
            >
              Videos
            </button>
            <input
              type="text"
              className="manageMedia-search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search media..."
              disabled={isLoading}
            />

            {/* Conditionally render the Upload button */}
            {!isRolesLoading && userRoles.includes('Media Manager') && (
              <button
                onClick={handleUploadClick}
                className="manageMedia-upload-button"
                disabled={isLoading}
              >
                Upload
              </button>
            )}

            {/* Conditionally show Edit/Delete if a media file is selected */}
            {selectedMediaFile && (
              <>
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="manageMedia-edit-button"
                  disabled={isLoading}
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={handleDeleteClick}
                  className="manageMedia-delete-button-red"
                  disabled={isLoading}
                >
                  Delete
                </button>
              </>
            )}

            {/* Toggle grid vs. list icon */}
            <button
              onClick={handleIconClick}
              className="manageMedia-icon-button"
              disabled={isLoading}
            >
              <img src={currentIcon} alt="Current Icon" className="manageMedia-icon-image" />
            </button>
          </div>

          {/* Render error message if roles fetching failed */}
          {rolesError && (
            <div className="manageMedia-error-message">
              Failed to load user roles. Please try again later.
            </div>
          )}

          {/* Breadcrumbs (subfolders) */}
          {renderBreadcrumbs()}

          {/* Folder dropdown at home level */}
          {renderFolderDropdown()}

          {/* Main media display (grid or list) */}
          <div className="manageMedia-media-display-grid">
            {isLoading ? (
              <div className="manageMedia-loading-indicator-within-grid">
                <div className="spinner"></div>
                <p>Loading media files...</p>
              </div>
            ) : (
              renderMediaFiles()
            )}
          </div>

          {/* Upload Overlay */}
          {isUploadOverlayVisible && (
            <div className="manageMedia-upload-overlay">
              <div className="manageMedia-upload-overlay-content">
                <button className="manageMedia-close-overlay-button" onClick={handleOverlayClose}>
                  X
                </button>
                <UploadMedia email={currentUser?.email} setSubHeader={() => {}} />
              </div>
            </div>
          )}

          {/* Edit Overlay */}
          {isEditOverlayVisible && (
            <div className="manageMedia-edit-overlay">
              <div className="manageMedia-edit-overlay-content">
                <button className="manageMedia-close-overlay-button" onClick={handleEditCancel}>
                  X
                </button>
                <h2>Edit Media</h2>
                <div className="manageMedia-edit-media-display">
                  {activeButton === 'image' ? (
                    <img
                      src={selectedMediaFile?.fileUrl}
                      alt="Selected Media"
                      className="manageMedia-edit-media-file"
                    />
                  ) : (
                    <video
                      src={selectedMediaFile?.fileUrl}
                      className="manageMedia-edit-media-file"
                      controls
                    />
                  )}
                </div>
                <form onSubmit={handleEditSubmit} className="manageMedia-edit-form">
                  <div className="manageMedia-edit-tags">
                    {editTags.map((tag, index) => (
                      <div key={index} className="manageMedia-edit-tag">
                        <input
                          type="text"
                          placeholder="Key"
                          value={tag.key}
                          onChange={(e) => handleEditTagChange(index, 'key', e.target.value)}
                          className="manageMedia-edit-tag-input"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Value"
                          value={tag.value}
                          onChange={(e) => handleEditTagChange(index, 'value', e.target.value)}
                          className="manageMedia-edit-tag-input"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveEditTag(index)}
                          className="manageMedia-edit-remove-tag-button"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                  {editTags.length < 10 && (
                    <button
                      type="button"
                      onClick={handleAddEditTag}
                      className="manageMedia-edit-add-tag-button"
                    >
                      Add Tag
                    </button>
                  )}
                  <div className="manageMedia-edit-actions">
                    <button
                      type="button"
                      onClick={handleEditCancel}
                      className="manageMedia-edit-cancel-button"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="manageMedia-edit-submit-button">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Video Modal */}
          {selectedVideo && (
            <div className="manageMedia-video-modal">
              <div className="manageMedia-video-content">
                <button onClick={() => setSelectedVideo(null)}>Close</button>
                <video src={selectedVideo} controls autoPlay />
              </div>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

/**
 * DraggableMedia component: each media item can be dragged within the grid.
 * Also handles selection/deselection on click.
 */
const DraggableMedia = ({
  fileName,
  fileUrl,
  index,
  moveMedia,
  moveMediaToFolder,
  activeButton,
  directories,
  selectedMediaFile,
  setSelectedMediaFile,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index, fileName, fileUrl },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        moveMedia(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      }
    },
    drop: (draggedItem, monitor) => {
      // Optional: Implement folder drop logic if necessary
    },
  });

  // Select / Deselect media file
  const handleClick = (e) => {
    e.stopPropagation();
    if (selectedMediaFile && selectedMediaFile.fileName === fileName) {
      setSelectedMediaFile(null);
    } else {
      setSelectedMediaFile({ fileName, fileUrl });
    }
  };

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={`manageMedia-media-item ${
        selectedMediaFile && selectedMediaFile.fileName === fileName ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      {activeButton === 'image' ? (
        <img src={fileUrl} alt="Media" className="manageMedia-media-file" />
      ) : (
        <video src={fileUrl} className="manageMedia-media-file" controls />
      )}
    </div>
  );
};

/**
 * DroppableFolder component: a folder icon that accepts dropped media items.
 */
const DroppableFolder = ({ folder, moveMediaToFolder, handleFolderClick, isLoading }) => {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: (draggedItem) => {
      console.log('Dropped item:', draggedItem);
      moveMediaToFolder(draggedItem.fileUrl, folder);
    },
  });

  return (
    <div
      ref={drop}
      className="manageMedia-folder-icon-container"
      onClick={() => handleFolderClick(folder)}
      style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
    >
      <img src={directoryIcon} alt="Folder Icon" className="manageMedia-folder-icon" />
      <span className="manageMedia-folder-name">{folder}</span>
    </div>
  );
};

export default ManageMedia;
