// ./src/components/AccountManagement/DefaultSettings/defaultSettings.js

import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance';
import {
  FaChevronDown,
  FaChevronRight,
  FaTimesCircle
} from 'react-icons/fa';
import DefaultSettingsOverlay from './defaultSettingsOverlay';
import './defaultSettings.css';

const DefaultSettings = () => {
  // 1. Add 'screenText' to the settingsList
  const settingsList = useMemo(() => [
    { key: 'screenTemplate', name: 'Screen Template' },
    { key: 'screenPin', name: 'Screen Pin' },
    { key: 'screenText', name: 'Screen Text' }, // NEW
  ], []);

  const [properties, setProperties] = useState([]);
  const [games, setGames] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [loadingGames, setLoadingGames] = useState(false);

  const [propertiesError, setPropertiesError] = useState(null);
  const [gamesError, setGamesError] = useState(null);

  // Defaults object from /api/getDefaults => { global: {}, property: {}, game: {} }
  const [defaults, setDefaults] = useState({
    global: {},
    property: {},
    game: {},
  });
  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [defaultsError, setDefaultsError] = useState(null);

  // Checkboxes for each setting: { screenTemplate: { global: bool, property: bool, game: bool }, ...}
  const initialCheckboxStates = useMemo(() => {
    return settingsList.reduce((acc, setting) => {
      acc[setting.key] = { global: false, property: false, game: false };
      return acc;
    }, {});
  }, [settingsList]);
  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);

  // Which setting is expanded to show sub-rows?
  const [expandedSettings, setExpandedSettings] = useState({});

  // Sub-section expansions
  const [subSectionExpanded, setSubSectionExpanded] = useState({});

  // Overlay State
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);  
  const [currentOption, setCurrentOption] = useState(null);    
  const [currentContextId, setCurrentContextId] = useState(null);

  // -----------------------------
  // 1) Fetch properties and games
  // -----------------------------
  useEffect(() => {
    const fetchProperties = async () => {
      setLoadingProperties(true);
      setPropertiesError(null);
      try {
        const response = await axiosInstance.get('/api/properties');
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setPropertiesError('Failed to load properties.');
        toast.error('Failed to load properties.');
      } finally {
        setLoadingProperties(false);
      }
    };

    const fetchGames = async () => {
      setLoadingGames(true);
      setGamesError(null);
      try {
        const response = await axiosInstance.get('/api/games');
        setGames(response.data); // e.g. [{ game_name: "Craps" }]
      } catch (error) {
        console.error('Error fetching games:', error);
        setGamesError('Failed to load games.');
        toast.error('Failed to load games.');
      } finally {
        setLoadingGames(false);
      }
    };

    fetchProperties();
    fetchGames();
  }, []);

  // -----------------------------
  // 2) Fetch defaults.yaml
  // -----------------------------
  useEffect(() => {
    // Only fetch defaults once properties AND games are fetched
    if (!properties.length && !games.length) return;

    const fetchDefaults = async () => {
      setLoadingDefaults(true);
      setDefaultsError(null);
      try {
        const response = await axiosInstance.get('/api/getDefaults');
        /*
          response.data = {
            global: {
              screenTemplate: "Template2",
              screenPin: "hashedpin...",
              screenText: "Line1|Line2|Line3"
            },
            property: {
              ChateauOhio: {
                screenTemplate: "Template1",
                screenPin: "hashedpin...",
                screenText: "Hello|From|Chateau"
              }
            },
            game: {
              Craps: {
                screenTemplate: "Template3"
                // screenPin is not set, screenText might or might not be set
              }
            }
          }
        */
        setDefaults(response.data);

        // Populate checkbox states
        const updatedCheckboxStates = JSON.parse(JSON.stringify(initialCheckboxStates));

        settingsList.forEach(({ key }) => {
          // Check global
          if (response.data.global[key]) {
            updatedCheckboxStates[key].global = true;
          }
          // Check properties
          const propertyNames = Object.keys(response.data.property);
          propertyNames.forEach((propName) => {
            if (response.data.property[propName][key]) {
              updatedCheckboxStates[key].property = true;
            }
          });
          // Check games
          const gameNames = Object.keys(response.data.game);
          gameNames.forEach((gmName) => {
            if (response.data.game[gmName][key]) {
              updatedCheckboxStates[key].game = true;
            }
          });
        });

        setCheckboxStates(updatedCheckboxStates);
      } catch (error) {
        console.error('Error fetching defaults:', error);
        setDefaultsError('Failed to load default settings.');
        toast.error('Failed to load default settings.');
      } finally {
        setLoadingDefaults(false);
      }
    };

    fetchDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, games]);

  // -----------------------------
  // 3) Initialize Sub-Section Expansion State
  // -----------------------------
  useEffect(() => {
    const initialSubSectionState = {};
    settingsList.forEach((setting) => {
      initialSubSectionState[setting.key] = {
        property: true,
        game: true,
      };
    });
    setSubSectionExpanded(initialSubSectionState);
  }, [settingsList]);

  // -----------------------------
  // 4) Overlay open/close
  // -----------------------------
  const openOverlay = (settingKey, optionKey, contextId = null) => {
    if (isOverlayOpen) return;

    setCurrentSetting(settingKey);
    setCurrentOption(optionKey);
    setCurrentContextId(contextId);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setCurrentSetting(null);
    setCurrentOption(null);
    setCurrentContextId(null);
  };

  // -----------------------------
  // 5) Checkbox changes
  // -----------------------------
  const handleCheckboxChange = (settingKey, optionKey, isChecked) => {
    // If user checks => open overlay
    // If unchecks => remove from scope
    setCheckboxStates((prevState) => ({
      ...prevState,
      [settingKey]: {
        ...prevState[settingKey],
        [optionKey]: isChecked,
      },
    }));

    if (isChecked) {
      openOverlay(settingKey, optionKey);
    } else {
      removeSetting(settingKey, optionKey);
    }
  };

  // -----------------------------
  // 6) Remove setting
  // -----------------------------
  const removeSetting = async (settingKey, optionKey) => {
    try {
      const payload = {
        action: 'remove',
        scope: optionKey,
        settingKey: settingKey,
      };

      await axiosInstance.post('/api/setDefaults', payload);
      toast.success(`Removed ${settingKey} from ${optionKey}`);

      // Update local defaults
      setDefaults((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        if (optionKey === 'global') {
          delete updated.global[settingKey];
        } else if (optionKey === 'property') {
          Object.keys(updated.property).forEach((propName) => {
            if (updated.property[propName][settingKey]) {
              delete updated.property[propName][settingKey];
              if (Object.keys(updated.property[propName]).length === 0) {
                delete updated.property[propName];
              }
            }
          });
        } else if (optionKey === 'game') {
          Object.keys(updated.game).forEach((gmName) => {
            if (updated.game[gmName][settingKey]) {
              delete updated.game[gmName][settingKey];
              if (Object.keys(updated.game[gmName]).length === 0) {
                delete updated.game[gmName];
              }
            }
          });
        }
        return updated;
      });
    } catch (error) {
      console.error(`Error removing ${settingKey} from ${optionKey}:`, error);
      toast.error(`Failed to remove ${settingKey} from ${optionKey}`);
    }
  };

  // -----------------------------
  // 7) Accordion toggles
  // -----------------------------
  const toggleAccordion = (settingKey) => {
    setExpandedSettings((prev) => ({
      ...prev,
      [settingKey]: !prev[settingKey],
    }));
  };

  // -----------------------------
  // 8) Toggle Sub-Sections
  // -----------------------------
  const toggleSubSection = (settingKey, subSectionKey) => {
    setSubSectionExpanded((prev) => ({
      ...prev,
      [settingKey]: {
        ...prev[settingKey],
        [subSectionKey]: !prev[settingKey][subSectionKey],
      },
    }));
  };

  // -----------------------------
  // 9) "Change" button in sub-rows
  // -----------------------------
  const handleChangeClick = (contextId, settingKey, optionKey) => {
    openOverlay(settingKey, optionKey, contextId);
  };

  // -----------------------------
  // Display helper
  // -----------------------------
  const displayDefaultValue = (settingKey, value) => {
    if (!value) return 'N/A';

    switch (settingKey) {
      case 'screenTemplate':
        return value; // Just a string
      case 'screenPin':
        return '******'; // Mask pins
      case 'screenText':
        // The raw pipe‐delimited string. Or you can show partial, etc.
        return value;
      default:
        return JSON.stringify(value);
    }
  };

  return (
    <div className="ds-container">
      {/* Loading / Error States */}
      {(loadingProperties || loadingGames || loadingDefaults) && (
        <div className="ds-loading">
          <div className="ds-spinner"></div> Loading data...
        </div>
      )}
      {(propertiesError || gamesError || defaultsError) && (
        <div className="ds-error-message">
          {propertiesError || gamesError || defaultsError}
        </div>
      )}

      {/* Main table */}
      {
        !loadingProperties &&
        !loadingGames &&
        !loadingDefaults &&
        !propertiesError &&
        !gamesError &&
        !defaultsError && (
          <table className="ds-default-settings-table">
            <thead>
              <tr>
                <th>Setting</th>
                <th>Global</th>
                <th>Property</th>
                <th>Game</th>
              </tr>
            </thead>
            <tbody>
              {settingsList.map((setting) => (
                <React.Fragment key={setting.key}>
                  {/* Main Setting Row */}
                  <tr>
                    <td className="ds-setting-cell">
                      <div className="ds-setting-content">
                        <span>{setting.name}</span>
                        {/* Expand arrow if property or game is checked */}
                        {(checkboxStates[setting.key].property || checkboxStates[setting.key].game) && (
                          <button
                            className="ds-accordion-toggle"
                            onClick={() => toggleAccordion(setting.key)}
                            aria-label={
                              expandedSettings[setting.key] ? 'Collapse' : 'Expand'
                            }
                            aria-expanded={expandedSettings[setting.key] ? 'true' : 'false'}
                            aria-controls={`${setting.key}-settings`}
                          >
                            {expandedSettings[setting.key] ? <FaChevronDown /> : <FaChevronRight />}
                          </button>
                        )}
                      </div>
                    </td>

                    {/* Global checkbox */}
                    <td>
                      <input
                        type="checkbox"
                        checked={checkboxStates[setting.key].global}
                        onChange={(e) =>
                          handleCheckboxChange(setting.key, 'global', e.target.checked)
                        }
                        aria-label={`${setting.name} - Global`}
                      />
                    </td>

                    {/* Property checkbox */}
                    <td>
                      <input
                        type="checkbox"
                        checked={checkboxStates[setting.key].property}
                        onChange={(e) =>
                          handleCheckboxChange(setting.key, 'property', e.target.checked)
                        }
                        aria-label={`${setting.name} - Property`}
                      />
                    </td>

                    {/* Game column
                        For screenPin we show a gray X instead of a checkbox
                        For others, normal checkbox */}
                    <td>
                      {setting.key === 'screenPin' ? (
                        <FaTimesCircle style={{ color: 'gray' }} />
                      ) : (
                        <input
                          type="checkbox"
                          checked={checkboxStates[setting.key].game}
                          onChange={(e) =>
                            handleCheckboxChange(setting.key, 'game', e.target.checked)
                          }
                          aria-label={`${setting.name} - Game`}
                        />
                      )}
                    </td>
                  </tr>

                  {/* Sub-rows for property / game */}
                  {expandedSettings[setting.key] && (
                    <>
                      {/* Property Sub-Section */}
                      {checkboxStates[setting.key].property && (
                        <>
                          <tr className="ds-sub-row-header">
                            <td colSpan="4">
                              <div className="ds-sub-section-header">
                                <strong>Properties</strong>
                                <button
                                  className="ds-subsection-toggle"
                                  onClick={() => toggleSubSection(setting.key, 'property')}
                                  aria-label={
                                    subSectionExpanded[setting.key]?.property
                                      ? 'Collapse Properties'
                                      : 'Expand Properties'
                                  }
                                  aria-expanded={
                                    subSectionExpanded[setting.key]?.property ? 'true' : 'false'
                                  }
                                >
                                  {subSectionExpanded[setting.key]?.property ? (
                                    <FaChevronDown />
                                  ) : (
                                    <FaChevronRight />
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                          {subSectionExpanded[setting.key]?.property && (
                            <>
                              {properties.length > 0 ? (
                                properties.map((prop) => {
                                  const propDefaults = defaults.property[prop.property_name];
                                  const currentVal = propDefaults
                                    ? propDefaults[setting.key]
                                    : null;
                                  return (
                                    <tr
                                      key={`prop-${prop.property_name}-${setting.key}`}
                                      className="ds-sub-row"
                                    >
                                      <td
                                        className="ds-sub-row-cell"
                                        style={{ paddingLeft: '60px' }}
                                      >
                                        {prop.property_name}
                                      </td>
                                      <td colSpan={1}>
                                        {currentVal
                                          ? displayDefaultValue(setting.key, currentVal)
                                          : 'N/A'}
                                      </td>
                                      <td colSpan={2}>
                                        <button
                                          className="ds-change-button"
                                          onClick={() =>
                                            handleChangeClick(
                                              prop.property_name,
                                              setting.key,
                                              'property'
                                            )
                                          }
                                        >
                                          Change
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="ds-sub-row">
                                  <td
                                    className="ds-sub-row-cell"
                                    style={{ paddingLeft: '60px' }}
                                  >
                                    No properties available.
                                  </td>
                                  <td>—</td>
                                  <td colSpan="2">
                                    <button className="ds-change-button" disabled>
                                      Change
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {/* Game Sub-Section */}
                      {checkboxStates[setting.key].game && (
                        <>
                          <tr className="ds-sub-row-header">
                            <td colSpan="4">
                              <div className="ds-sub-section-header">
                                <strong>Games</strong>
                                <button
                                  className="ds-subsection-toggle"
                                  onClick={() => toggleSubSection(setting.key, 'game')}
                                  aria-label={
                                    subSectionExpanded[setting.key]?.game
                                      ? 'Collapse Games'
                                      : 'Expand Games'
                                  }
                                  aria-expanded={
                                    subSectionExpanded[setting.key]?.game ? 'true' : 'false'
                                  }
                                >
                                  {subSectionExpanded[setting.key]?.game ? (
                                    <FaChevronDown />
                                  ) : (
                                    <FaChevronRight />
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                          {subSectionExpanded[setting.key]?.game && (
                            <>
                              {games.length > 0 ? (
                                games.map((gm) => {
                                  const gameDefaults = defaults.game[gm.game_name];
                                  const currentVal = gameDefaults
                                    ? gameDefaults[setting.key]
                                    : null;
                                  return (
                                    <tr
                                      key={`game-${gm.game_name}-${setting.key}`}
                                      className="ds-sub-row"
                                    >
                                      <td
                                        className="ds-sub-row-cell"
                                        style={{ paddingLeft: '60px' }}
                                      >
                                        {gm.game_name}
                                      </td>
                                      <td colSpan={1}>
                                        {currentVal
                                          ? displayDefaultValue(setting.key, currentVal)
                                          : 'N/A'}
                                      </td>
                                      <td colSpan={2}>
                                        <button
                                          className="ds-change-button"
                                          onClick={() =>
                                            handleChangeClick(gm.game_name, setting.key, 'game')
                                          }
                                        >
                                          Change
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="ds-sub-row">
                                  <td
                                    className="ds-sub-row-cell"
                                    style={{ paddingLeft: '60px' }}
                                  >
                                    No games available.
                                  </td>
                                  <td>—</td>
                                  <td colSpan="2">
                                    <button className="ds-change-button" disabled>
                                      Change
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )
      }

      {/* Overlay */}
      {isOverlayOpen && currentSetting && currentOption && (
        <DefaultSettingsOverlay
          settingKey={currentSetting}
          scope={currentOption}
          contextId={currentContextId}
          properties={properties}
          games={games}
          defaults={defaults}
          onClose={closeOverlay}
          onUpdateDefaults={setDefaults}
        />
      )}
    </div>
  );
};

export default DefaultSettings;
