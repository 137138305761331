import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance'; // Updated to use axiosInstance
import './mediaInfo.css';

const MediaInfo = () => {
    const [stats, setStats] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch media statistics
    const fetchStats = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/mediaInfo/stats');
            setStats(response.data);
        } catch (err) {
            console.error('Error fetching media statistics:', err);
            setError('Failed to load media statistics.');
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch statistics on component mount
    useEffect(() => {
        fetchStats();
    }, []);

    if (isLoading) return <div className="loading">Loading statistics...</div>;
    if (error) return <div className="error">{error}</div>;
    if (!stats) return null;

    return (
        <div className="media-info">
            <header className="media-info-header">
                <h2>Media Statistics</h2>
            </header>

            <div className="statistics-container">
              <div className="statistics-row-1">
                <div className="row-1-stat">
                    <h3>Total Media</h3>
                    <p>{stats.totalMedia}</p>
                </div>

                <div className="row-1-stat">
                    <h3>Media Breakdown</h3>
                    <p>Images: {stats.typeBreakdown.Images}</p>
                    <p>Videos: {stats.typeBreakdown.Videos}</p>
                </div>

                <div className="row-1-stat">
                    <h3>Total Storage Used</h3>
                    <p>{(stats.totalStorage / 1e6).toFixed(2)} MB</p>
                </div>

                <div className="row-1-stat">
                    <h3>Storage Breakdown</h3>
                    <p>Images: {(stats.storageBreakdown.Images / 1e6).toFixed(2)} MB</p>
                    <p>Videos: {(stats.storageBreakdown.Videos / 1e6).toFixed(2)} MB</p>
                </div>
              </div>

              <div className="statistics-row-2">
                <div className="row-2-stat-average">
                    <h3>Average File Size</h3>
                    <p>Images: {(stats.averageSize.Images / 1e3).toFixed(2)} KB</p>
                    <p>Videos: {(stats.averageSize.Videos / 1e3).toFixed(2)} KB</p>
                </div>

                <div className="row-2-stat-largest">
                    <h3>Largest Files</h3>
                    <p>Image: {stats.largestFile.Images?.name || 'N/A'} ({(stats.largestFile.Images?.size / 1e6).toFixed(2)} MB)</p>
                    <p>Video: {stats.largestFile.Videos?.name || 'N/A'} ({(stats.largestFile.Videos?.size / 1e6).toFixed(2)} MB)</p>
                </div>

                <div className="row-2-stat-smallest">
                    <h3>Smallest Files</h3>
                    <p>Image: {stats.smallestFile.Images?.name || 'N/A'} ({(stats.smallestFile.Images?.size / 1e3).toFixed(2)} KB)</p>
                    <p>Video: {stats.smallestFile.Videos?.name || 'N/A'} ({(stats.smallestFile.Videos?.size / 1e3).toFixed(2)} KB)</p>
                </div>
              </div>
            </div>

            <div className="upload-activity-section">
              <div className="upload-activity-title">Recent Uploads</div>
              <div className="upload-activity">
                {stats.uploadActivity.length > 0 ? (
                  <ul>
                    {stats.uploadActivity.map((activity, index) => (
                      <li key={index}>
                        <p><strong>Name:</strong> {activity.name}</p>
                        <p><strong>Type:</strong> {activity.type}</p>
                        <p><strong>Uploaded By:</strong> {activity.uploadedBy}</p>
                        <p><strong>Uploaded At:</strong> {new Date(activity.uploadedAt).toLocaleString()}</p>
                        <p><strong>Size:</strong> {(activity.size / 1e3).toFixed(2)} KB</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No recent uploads available.</p>
                )}
              </div>
            </div>
            </div>
    );
};

export default MediaInfo;
