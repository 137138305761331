import React, { useState } from 'react';
import ConfigureDevice from './DeviceManagement/ConfigureDevice';
import DeviceSupportTicket from './DeviceManagement/DeviceSupportTicket';
import Monitoring from './DeviceManagement/Monitoring';
import './DeviceManagement.css';

const DeviceManagement = () => {
  const [activeSection, setActiveSection] = useState('monitoring');

  const renderSection = () => {
    switch (activeSection) {
      case 'monitoring':
        return <Monitoring />;
      case 'configureDevice':
        return <ConfigureDevice />;
      case 'resetDevice':
        return <div className="section-content">Reset Device Section - Coming Soon</div>;
      case 'deviceSupportTicket':
        return <DeviceSupportTicket />;
      default:
        return <div className="section-content">Select a section</div>;
    }
  };

  return (
    <div className="device-management-container">
      <div className="sidebar">
        <ul>
          <li
            className={activeSection === 'monitoring' ? 'active' : ''}
            onClick={() => setActiveSection('monitoring')}
          >
            Monitoring
          </li>
          <li
            className={activeSection === 'configureDevice' ? 'active' : ''}
            onClick={() => setActiveSection('configureDevice')}
          >
            Configure Device
          </li>
          <li
            className={activeSection === 'resetDevice' ? 'active' : ''}
            onClick={() => setActiveSection('resetDevice')}
          >
            Reset Device
          </li>
          <li
            className={activeSection === 'deviceSupportTicket' ? 'active' : ''}
            onClick={() => setActiveSection('deviceSupportTicket')}
          >
            Device Support Ticket
          </li>
        </ul>
      </div>
      <div className="main-content">{renderSection()}</div>
    </div>
  );
};

export default DeviceManagement;
