import React, { useState } from 'react';
import UserManagement from './AccountManagement/UserManagement';
import SecurityCompliance from './AccountManagement/SecurityCompliance';
import CompanyProfile from './AccountManagement/CompanyProfile'; // Import your new component
import './AccountManagement.css';

const AccountManagement = () => {
  const [activeSection, setActiveSection] = useState('companyProfile');

  const renderSection = () => {
    switch (activeSection) {
      case 'companyProfile':
        return <CompanyProfile />;
      case 'userManagement':
        return <UserManagement />;
      case 'securityCompliance':
        return <SecurityCompliance />;
      case 'licensingSupport':
        return <div className="section-content">Licensing &amp; Support Section - Coming Soon</div>;
      default:
        return <div className="section-content">Select a section</div>;
    }
  };

  return (
    <div className="account-management-container">
      <div className="sidebar">
        <ul>
          <li
            className={activeSection === 'companyProfile' ? 'active' : ''}
            onClick={() => setActiveSection('companyProfile')}
          >
            Company Profile
          </li>
          <li
            className={activeSection === 'userManagement' ? 'active' : ''}
            onClick={() => setActiveSection('userManagement')}
          >
            User Management
          </li>
          {/*
            If you decide to bring back API Keys & Integrations:
            <li
              className={activeSection === 'apiKeysIntegrations' ? 'active' : ''}
              onClick={() => setActiveSection('apiKeysIntegrations')}
            >
              API Keys & Integrations
            </li>
          */}
          <li
            className={activeSection === 'securityCompliance' ? 'active' : ''}
            onClick={() => setActiveSection('securityCompliance')}
          >
            Security &amp; Compliance
          </li>
          <li
            className={activeSection === 'licensingSupport' ? 'active' : ''}
            onClick={() => setActiveSection('licensingSupport')}
          >
            Licensing &amp; Support
          </li>
        </ul>
      </div>
      <div className="main-content">{renderSection()}</div>
    </div>
  );
};

export default AccountManagement;
