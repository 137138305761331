// src/components/ConfigureDeviceForm.js

import React, { useState } from 'react';
import Toggle from './Toggle'; // Ensure correct path
import './ConfigureDeviceForm.css'; // Ensure correct path

const ConfigureDeviceForm = ({
  isOnline,
  setIsOnline,
  game,
  games,
  setGame,
  minBet,
  setMinBet,
  maxBet,
  setMaxBet,
  submittedMinBet,
  submittedMaxBet,
  handleSubmit,
  selectedImage,
  setSelectedImage, // Accept setSelectedImage as a prop
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleImageSelect = (imageIndex) => {
    if (selectedImage === imageIndex) {
      setSelectedImage(null); // Deselect if the same image is clicked
      console.log(`Deselected image ${imageIndex}`);
    } else {
      setSelectedImage(imageIndex);
      console.log(`Selected image ${imageIndex}`);
    }
  };

  return (
    <div className="configure-device-form">
      <h2>Configure Device</h2>
      <form onSubmit={handleSubmit}>
        {/* Toggle for Online/Offline */}
        <Toggle isOnline={isOnline} setIsOnline={setIsOnline} />

        {/* Conditional Fields Based on Toggle */}
        {isOnline && (
          <>
            {/* Game Selection */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="game">Game:</label>
              </div>
              <select
                id="game"
                value={game}
                onChange={(e) => setGame(e.target.value)}
                required
              >
                <option value="">Select Game</option>
                {games.map((gameItem, index) => (
                  <option key={index} value={gameItem.game_name}>
                    {gameItem.game_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Min Bet */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="minBet">Min Bet:</label>
                <span className="current-value">Current: ${submittedMinBet}</span>
              </div>
              <input
                type="text"
                id="minBet"
                value={minBet}
                onChange={(e) => setMinBet(e.target.value)}
                pattern="\d*"
                title="Please enter a whole number"
                placeholder="Enter Min Bet"
                required
              />
            </div>

            {/* Max Bet */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="maxBet">Max Bet:</label>
                <span className="current-value">Current: ${submittedMaxBet}</span>
              </div>
              <input
                type="text"
                id="maxBet"
                value={maxBet}
                onChange={(e) => setMaxBet(e.target.value)}
                pattern="\d*"
                title="Please enter a whole number"
                placeholder="Enter Max Bet"
                required
              />
            </div>
          </>
        )}

        {/* Dropdown for Image Selection */}
        <div className="image-dropdown-container">
          <button
            type="button"
            className="dropdown-toggle-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {isDropdownOpen ? 'Hide Templates' : 'Show Templates'}
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <div className="image-grid">
                {[1, 2, 3].map((index) => (
                  <div
                    key={index}
                    className={`image-item ${selectedImage === index ? 'selected' : ''}`}
                    onClick={() => handleImageSelect(index)}
                  >
                    <div className="checkbox-overlay">
                      <input
                        type="checkbox"
                        readOnly
                        checked={selectedImage === index}
                        onChange={() => {}}
                      />
                    </div>
                    <img
                      src={`/templates/template${index}.png`}
                      alt={`Template ${index}`}
                      className="template-image"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ConfigureDeviceForm;
