import React from 'react';
import './companyProfile.css';

const CompanyProfileSection = () => {
  return (
    <div className="cps-company-profile cps-scrollable cps-modern-design">
      <div className="cps-company-header">
        <div className="cps-company-logo">
          <img src="/monitoring/logo.png" alt="Heritage Forge Gaming Logo" className="cps-company-logo-img" />
        </div>
        <div className="cps-company-intro">
          <h1 className="cps-modern-header">Heritage Forge Gaming</h1>
          <p className="cps-modern-text">
            Heritage Forge Gaming is a premier US-based casino and hospitality company headquartered in Saratoga Springs, New York. With a legacy of excellence rooted in history and craftsmanship, Heritage Forge Gaming combines timeless elegance with state-of-the-art gaming experiences. The company operates two flagship properties in the Northeast and Ohio Valley regions, offering unmatched entertainment, dining, and luxury accommodations.
          </p>
          <div className="cps-company-mission">
            <h2 className="cps-modern-subheader">Mission Statement</h2>
            <blockquote className="cps-modern-quote">
              “To honor our rich heritage while delivering unparalleled gaming and hospitality experiences that forge unforgettable memories for our guests and communities.”
            </blockquote>
          </div>
        </div>
      </div>

      <div className="cps-key-statistics">
        <h2 className="cps-modern-subheader">Key Statistics</h2>
        <div className="cps-statistics-grid">
          <div className="cps-stat-item">
            <strong>Number of Properties:</strong>
            <p>2</p>
          </div>
          <div className="cps-stat-item">
            <strong>Number of Gaming Floors:</strong>
            <p>4</p>
          </div>
          <div className="cps-stat-item">
            <strong>Total Table Games:</strong>
            <p>120</p>
          </div>
          <div className="cps-stat-item">
            <strong>Average per Property:</strong>
            <p>60 table games</p>
          </div>
        </div>
      </div>

      <div className="cps-properties">
        <h2 className="cps-modern-subheader">Properties</h2>

        <div className="cps-property cps-modern-card">
          <h3 className="cps-modern-card-header">Heritage Forge Casino & Resort - Saratoga Springs, NY</h3>
          <ul>
            <li><strong>Gaming Floors:</strong>
              <ul>
                <li>The Anvil Room: A luxurious high-stakes gaming floor.</li>
                <li>The Forge Hall: A vibrant gaming area with a mix of table games and slots.</li>
              </ul>
            </li>
            <li><strong>Table Games:</strong> 60</li>
            <li><strong>Contact Information:</strong>
              <ul>
                <li>Address: 100 Forge Lane, Saratoga Springs, NY 12866</li>
                <li>Phone: (518) 555-1212</li>
                <li>Email: <a href="mailto:info.saratoga@heritageforgegaming.com">info.saratoga@heritageforgegaming.com</a></li>
              </ul>
            </li>
            <li><strong>Key Personnel:</strong>
              <ul>
                <li>Property President: Margaret Turner</li>
                <li>Director of Table Games: David Mercer</li>
                <li>Marketing Director: Jessica Ruiz</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="cps-property cps-modern-card">
          <h3 className="cps-modern-card-header">Heritage Forge Casino - Ohio Valley, OH</h3>
          <ul>
            <li><strong>Gaming Floors:</strong>
              <ul>
                <li>The River Forge: A scenic gaming floor overlooking the Ohio River.</li>
                <li>The Iron Hall: A bustling gaming space with diverse offerings.</li>
              </ul>
            </li>
            <li><strong>Table Games:</strong> 60</li>
            <li><strong>Contact Information:</strong>
              <ul>
                <li>Address: 200 Heritage Blvd, Ohio Valley, OH 43950</li>
                <li>Phone: (740) 555-3434</li>
                <li>Email: <a href="mailto:info.ohiovalley@heritageforgegaming.com">info.ohiovalley@heritageforgegaming.com</a></li>
              </ul>
            </li>
            <li><strong>Key Personnel:</strong>
              <ul>
                <li>Property President: Charles Hensley</li>
                <li>Director of Table Games: Laura Fields</li>
                <li>Marketing Director: Michael Adams</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="cps-future-vision">
        <h2 className="cps-modern-subheader">Future Vision</h2>
        <p className="cps-modern-text">
          Heritage Forge Gaming is committed to expansion and innovation, with plans to grow its portfolio to include new properties in strategic locations while maintaining its focus on creating memorable experiences steeped in history and luxury.
        </p>
      </div>
    </div>
  );
};

export default CompanyProfileSection;
