// ButtonsAndSearchBar.js
import React from 'react';
import PropTypes from 'prop-types';

/**
 * ButtonsAndSearchBar Component
 * 
 * Renders media type buttons (Images, Videos), a search bar, an optional Upload button,
 * and a Commit button with an underglow effect when changes are staged.
 * 
 * Props:
 * - handleImagesClick: Function to handle clicking the Images button.
 * - handleVideosClick: Function to handle clicking the Videos button.
 * - activeButton: String indicating the currently active media type ('images' or 'videos').
 * - searchTerm: String representing the current search term.
 * - setSearchTerm: Function to update the search term.
 * - handleUploadClick: Function to handle clicking the Upload button.
 * - handleCommit: Function to handle clicking the Commit button.
 * - isOrderChanged: Boolean indicating if the order of manifest files has changed.
 * - canUpload: Boolean indicating if the Upload button should be displayed based on user roles.
 */
const ButtonsAndSearchBar = ({
  handleImagesClick,
  handleVideosClick,
  activeButton,
  searchTerm,
  setSearchTerm,
  handleUploadClick,
  handleCommit,
  isOrderChanged,
  canUpload, // New prop to determine if Upload button should be shown
}) => {
  return (
    <div className="content-manager-buttons">
      {/* Media Type Buttons */}
      <button
        onClick={handleImagesClick}
        className={activeButton === 'images' ? 'active' : ''}
        aria-pressed={activeButton === 'images'}
        aria-label="Filter images"
      >
        Images
      </button>
      <button
        onClick={handleVideosClick}
        className={activeButton === 'videos' ? 'active' : ''}
        aria-pressed={activeButton === 'videos'}
        aria-label="Filter videos"
      >
        Videos
      </button>

      {/* Search Bar */}
      <input
        type="text"
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search content..."
        aria-label="Search content"
      />

      {/* Conditionally Render the Upload Button Based on canUpload Prop */}
      {canUpload && (
        <button
          onClick={handleUploadClick}
          className="upload-button"
          aria-label="Upload media"
        >
          Upload
        </button>
      )}

      {/* Commit Button with Underglow Effect */}
      <button
        className={`commit-button ${isOrderChanged ? 'underglow' : ''}`}
        onClick={handleCommit}
        aria-label="Commit changes"
        disabled={!isOrderChanged} // Optionally disable if no changes are made
      >
        Commit
      </button>
    </div>
  );
};

// Define PropTypes for better type checking and documentation
ButtonsAndSearchBar.propTypes = {
  handleImagesClick: PropTypes.func.isRequired,
  handleVideosClick: PropTypes.func.isRequired,
  activeButton: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  handleCommit: PropTypes.func.isRequired,
  isOrderChanged: PropTypes.bool.isRequired,
  canUpload: PropTypes.bool.isRequired, // New prop for RBAC
};

export default ButtonsAndSearchBar;
