// src/components/ContentManager.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import './ContentManager.css';
import UploadMedia from './UploadMedia';
import ImagePreview from './ImagePreview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import the new components
import CollapsibleSidebar from './CollapsibleSidebar';
import AvailableFilesList from './AvailableFilesList';
import ManifestFilesList from './ManifestFilesList';
import ButtonsAndSearchBar from './ButtonsAndSearchBar';
import LoadingIndicator from './LoadingIndicator';

// Import the custom hook
import useUserRoles from '../hooks/useUserRoles';

// Constants for media types
const MEDIA_TYPES = {
  IMAGES: 'images',
  VIDEOS: 'videos',
};

const ContentManager = ({ setSubHeader }) => {
  // **State Variables**

  // Media and Manifest States
  const [mediaFiles, setMediaFiles] = useState([]); // All Available Files
  const [manifestFiles, setManifestFiles] = useState({
    Online: [],
    Offline: [],
  }); // Manifest Files for both tabs
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploadOverlayVisible, setIsUploadOverlayVisible] = useState(false);

  // Collapsible Sections States
  const [isContainerCollapsed, setIsContainerCollapsed] = useState(false);
  const [isPropertyCollapsed, setIsPropertyCollapsed] = useState(false);
  const [isFloorCollapsed, setIsFloorCollapsed] = useState(false);
  const [isPitCollapsed, setIsPitCollapsed] = useState(false);
  const [isFolderCollapsed, setIsFolderCollapsed] = useState(false); // New state for Folders

  // Preview Modal States
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState(null);

  // Selection States
  const [properties, setProperties] = useState([]);
  const [floors, setFloors] = useState([]);
  const [pits, setPits] = useState([]);
  const [tables, setTables] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedFloor, setSelectedFloor] = useState('');
  const [selectedPit, setSelectedPit] = useState('');
  const [selectedTable, setSelectedTable] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(''); // New state for selected Folder
  const [folders, setFolders] = useState([]); // New state for list of Folders

  // Other States
  const [activeButton, setActiveButton] = useState(MEDIA_TYPES.IMAGES); // 'images' or 'videos'
  const [tableSID, setTableSID] = useState('');
  const [bucketName, setBucketName] = useState('');
  const [customerId, setCustomerId] = useState(''); // New state for customer ID

  // **New States for Staged Changes**
  const [stagedAdditions, setStagedAdditions] = useState({
    Online: [],
    Offline: [],
  }); // Files to be added for both tabs
  const [stagedRemovals, setStagedRemovals] = useState({
    Online: [],
    Offline: [],
  }); // Files to be removed for both tabs

  // **State for Commit Button Underglow Effect**
  const [isOrderChanged, setIsOrderChanged] = useState(false);

  // **Loading State**
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  const auth = getAuth();
  const currentUser = auth.currentUser;

  // **Ref for Fetch Cancellation**
  const fetchCancelSource = useRef(null);

  // **Ref for Friendly Name Cache**
  const friendlyNameCache = useRef({});

  // **Ref for Switching Between Online and Offline**
  const [activeManifestTab, setActiveManifestTab] = useState('Online'); // "Online" or "Offline"

  // **RBAC-related States from the Custom Hook**
  const { userRoles, isRolesLoading, error: rolesError } = useUserRoles();

  // **Helper Functions**

  // Switch between Online and Offline
  const handleManifestTabClick = (tab) => {
    setActiveManifestTab(tab);
  };

  // Fetch friendly name for a single file using /api/mediaLookup
  const fetchFriendlyName = useCallback(
    async (fileName) => {
      if (friendlyNameCache.current[fileName]) {
        return friendlyNameCache.current[fileName];
      }

      if (!currentUser) {
        console.error('User is not authenticated');
        return 'Unknown'; // Provide a clear fallback
      }
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/mediaLookup',
          { fileName },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200 && response.data.length > 0) {
          // Assuming the first match is the desired one
          const friendlyName = response.data[0].friendly_name || 'Unknown';
          friendlyNameCache.current[fileName] = friendlyName;
          return friendlyName;
        } else {
          console.warn(`No friendly name found for file: ${fileName}`);
          friendlyNameCache.current[fileName] = 'Unknown';
          return 'Unknown'; // Fallback to 'Unknown' if no friendlyName is found
        }
      } catch (error) {
        console.error(`Error fetching friendly name for file ${fileName}:`, error);
        friendlyNameCache.current[fileName] = 'Unknown';
        return 'Unknown'; // Fallback to 'Unknown' on error
      }
    },
    [currentUser]
  );

  // Fetch friendly names for multiple files concurrently
  const fetchFriendlyNames = useCallback(
    async (fileNames) => {
      const friendlyNamePromises = fileNames.map((fileName) => fetchFriendlyName(fileName));
      const friendlyNames = await Promise.all(friendlyNamePromises);
      return friendlyNames;
    },
    [fetchFriendlyName]
  );

  // Helper function to determine media type based on file extension
  const getKeyForFile = (fileName) => {
    const lowerCaseName = fileName.toLowerCase();
    if (
      lowerCaseName.endsWith('.jpg') ||
      lowerCaseName.endsWith('.jpeg') ||
      lowerCaseName.endsWith('.png')
    ) {
      return 'Image';
    } else if (
      lowerCaseName.endsWith('.mp4') ||
      lowerCaseName.endsWith('.mov') ||
      lowerCaseName.endsWith('.avi')
    ) {
      return 'Video';
    } else {
      return 'Media'; // Fallback or handle unknown types as needed
    }
  };

  // Handle Media Button Click (Consolidated function)
  const handleMediaClick = useCallback(
    async (mediaType) => {
      setActiveButton(mediaType);
      console.log(`activeButton set to ${mediaType}`);
      setSelectedFolder(''); // Reset folder filter when media type changes
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        // Cancel any ongoing fetch
        if (fetchCancelSource.current) {
          fetchCancelSource.current.cancel('Operation canceled due to new request.');
        }
        // Create new cancel token
        fetchCancelSource.current = axios.CancelToken.source();

        const token = await currentUser.getIdToken(true);
        const response = await axios.get(
          'https://project-download-upload.wn.r.appspot.com/api/media',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              type: mediaType === MEDIA_TYPES.IMAGES ? 'image' : 'video',
              table_sid: tableSID, // Include table_sid if available
            },
            cancelToken: fetchCancelSource.current.token,
          }
        );

        let files = response.data;

        // **Exclude Staged Additions**
        const currentTab = activeManifestTab;
        if (stagedAdditions[currentTab].length > 0) {
          const stagedAdditionFiles = new Set(
            stagedAdditions[currentTab].map((item) => item.file)
          );
          files = files.filter((file) => !stagedAdditionFiles.has(file));
        }

        // Fetch friendly names
        const friendlyNames = await fetchFriendlyNames(files);

        // Combine filenames with their friendly names
        const mediaWithFriendlyNames = files.map((file, index) => ({
          file,
          friendlyName: friendlyNames[index],
        }));

        // **Set mediaFiles with fetched media**
        setMediaFiles(mediaWithFriendlyNames);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled:', error.message);
        } else {
          console.error(`Error fetching ${mediaType}:`, error);
          toast.error(`Failed to fetch ${mediaType}. Please try again.`);
        }
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser, tableSID, fetchFriendlyNames, stagedAdditions, activeManifestTab]
  );

  // Fetch Customer ID and Bucket Name
  const fetchCustomerIdAndBucketName = useCallback(async () => {
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const token = await currentUser.getIdToken(true);
      const customerResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerLookup',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedCustomerId = customerResponse.data.customer_id;
      setCustomerId(fetchedCustomerId);

      const bucketResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerUID',
        {
          params: { customer_id: fetchedCustomerId },
        }
      );

      setBucketName(bucketResponse.data.customer_uid);
    } catch (error) {
      console.error('Error fetching customer ID and bucket name:', error);
      toast.error('Failed to fetch customer details. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  }, [currentUser]);

  // Fetch Properties
  const fetchProperties = useCallback(async () => {
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const token = await currentUser.getIdToken(true);
      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/properties',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
      toast.error('Failed to fetch properties. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  }, [currentUser]);

  // Fetch Folders (Tags)
  const fetchFolders = useCallback(async () => {
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const token = await currentUser.getIdToken(true);
      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/media/fetchDirectories',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { customer_id: customerId }, // Pass customer_id as a parameter
        }
      );
      setFolders(response.data.directories);
    } catch (error) {
      console.error('Error fetching folders:', error);
      toast.error('Failed to fetch folders. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  }, [currentUser, customerId]);

  // Initial Data Fetching
  useEffect(() => {
    setSubHeader('Content Manager');
    if (currentUser) {
      fetchProperties();
      fetchCustomerIdAndBucketName();
      handleMediaClick(MEDIA_TYPES.IMAGES); // Initial fetch for images
    } else {
      console.error('User is not authenticated');
    }
  }, [currentUser, setSubHeader, fetchProperties, fetchCustomerIdAndBucketName, handleMediaClick]);

  // Fetch Folders once customerId is available
  useEffect(() => {
    if (customerId) {
      fetchFolders(); // Fetch folders once customerId is available
    }
  }, [customerId, fetchFolders]);

  // Fetch Floors based on Property
  const fetchFloors = useCallback(
    async (property_name) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(
          `https://project-download-upload.wn.r.appspot.com/api/floors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              property_name,
            },
          }
        );
        setFloors(response.data);
      } catch (error) {
        console.error('Error fetching floors:', error);
        toast.error('Failed to fetch floors. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser]
  );

  // Fetch Pits based on Floor and Property
  const fetchPits = useCallback(
    async (floor_type, property_name) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(
          `https://project-download-upload.wn.r.appspot.com/api/pits`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              floor_type,
              property_name,
            },
          }
        );
        setPits(response.data);
      } catch (error) {
        console.error('Error fetching pits:', error);
        toast.error('Failed to fetch pits. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser]
  );

  // Fetch Tables based on Floor, Pit, and Property
  const fetchTables = useCallback(
    async (floor_type, floor_name, property_name) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(
          `https://project-download-upload.wn.r.appspot.com/api/tables`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              floor_type,
              floor_name,
              property_name,
            },
          }
        );
        setTables(response.data);
      } catch (error) {
        console.error('Error fetching tables:', error);
        toast.error('Failed to fetch tables. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser]
  );

  // Fetch Media Files (filtered by folder and table if applicable)
  const fetchMediaFiles = useCallback(
    async (currentTableSID = tableSID, folderTag = selectedFolder) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        // Cancel any ongoing fetch
        if (fetchCancelSource.current) {
          fetchCancelSource.current.cancel('Operation canceled due to new request.');
        }
        // Create new cancel token
        fetchCancelSource.current = axios.CancelToken.source();

        const token = await currentUser.getIdToken(true);
        const params = {
          type: activeButton === MEDIA_TYPES.IMAGES ? 'image' : 'video',
        };
        if (folderTag) {
          params.folder = folderTag;
        }
        if (currentTableSID) {
          params.table_sid = currentTableSID; // Include table_sid if available
        }

        const response = await axios.get(
          'https://project-download-upload.wn.r.appspot.com/api/media',
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
            cancelToken: fetchCancelSource.current.token,
          }
        );

        let files = response.data;

        // **Exclude Staged Additions**
        const currentTab = activeManifestTab;
        if (stagedAdditions[currentTab].length > 0) {
          const stagedAdditionFiles = new Set(
            stagedAdditions[currentTab].map((item) => item.file)
          );
          files = files.filter((file) => !stagedAdditionFiles.has(file));
        }

        // Fetch friendly names
        const friendlyNames = await fetchFriendlyNames(files);

        // Combine filenames with their friendly names
        const mediaWithFriendlyNames = files.map((file, index) => ({
          file,
          friendlyName: friendlyNames[index],
        }));

        // **Set mediaFiles with fetched media**
        setMediaFiles(mediaWithFriendlyNames);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled:', error.message);
        } else {
          console.error('Error fetching media files:', error);
          toast.error('Failed to fetch media files. Please try again.');
        }
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser, activeButton, selectedFolder, tableSID, fetchFriendlyNames, stagedAdditions, activeManifestTab]
  );

  // Handle Floor Selection
  const handleFloorChange = useCallback(
    async (floorType) => {
      setSelectedFloor(floorType);
      setIsFloorCollapsed(true);
      await fetchPits(floorType, selectedProperty);
      setSelectedPit('');
      setSelectedTable('');
      setTables([]);
      setSelectedFolder(''); // Reset selected folder
    },
    [fetchPits, selectedProperty]
  );

  // Handle Pit Selection
  const handlePitChange = useCallback(
    async (pitName) => {
      setSelectedPit(pitName);
      setIsPitCollapsed(true);
      await fetchTables(selectedFloor, pitName, selectedProperty);
      setSelectedTable('');
      setSelectedFolder(''); // Reset selected folder
    },
    [fetchTables, selectedFloor, selectedProperty]
  );

  // Handle Property Selection
  const handlePropertyChange = useCallback(
    async (propertyName) => {
      setSelectedProperty(propertyName);
      setIsPropertyCollapsed(true);
      await fetchFloors(propertyName);
      setSelectedFloor('');
      setSelectedPit('');
      setSelectedTable('');
      setPits([]);
      setTables([]);
      setSelectedFolder(''); // Reset selected folder
    },
    [fetchFloors]
  );

  // Handle Table Selection
  const handleTableChange = useCallback(
    async (table) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }

      // Reset manifest and other related states when a new table is selected
      setManifestFiles({ Online: [], Offline: [] }); // Clear the current manifest files
      setTableSID(''); // Reset table SID
      setSelectedTable(table.table_name);
      setSelectedFolder(''); // Reset selected folder

      setIsLoading(true); // Start loading
      try {
        // Cancel any ongoing fetch
        if (fetchCancelSource.current) {
          fetchCancelSource.current.cancel('Operation canceled due to new request.');
        }
        // Create new cancel token
        fetchCancelSource.current = axios.CancelToken.source();

        const token = await currentUser.getIdToken(true);

        // Determine if selectedProperty is an ID (integer) or a name (string)
        const selectedPropertyNumber = Number(selectedProperty);
        const propertyParam = Number.isInteger(selectedPropertyNumber)
          ? { property_id: selectedPropertyNumber }
          : { property_name: selectedProperty };

        // Step 1: Fetch the table SID based on the selected table
        const sidResponse = await axios.get(
          'https://project-download-upload.wn.r.appspot.com/api/table-sid',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              customer_id: customerId,
              ...propertyParam,
              floor_type: selectedFloor,
              floor_name: selectedPit,
              table_name: table.table_name,
            },
            cancelToken: fetchCancelSource.current.token,
          }
        );

        const fetchedTableSID = sidResponse.data.table_sid;
        if (fetchedTableSID) {
          setTableSID(fetchedTableSID);

          // Step 2: Update tempManifest.yaml and tempManifestOff.yaml with the current state of manifest.yaml and manifestOff.yaml
          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/updateTempManifest',
            {
              propertyName: selectedProperty,
              floorType: selectedFloor,
              floorName: selectedPit,
              tableSid: fetchedTableSID, // Use the fetched table SID
              bucket: bucketName,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          console.log('tempManifest.yaml and tempManifestOff.yaml updated successfully');

          // Step 3: Load the manifest data
          const manifestResponse = await axios.get(
            'https://project-download-upload.wn.r.appspot.com/api/manifest',
            {
              headers: { Authorization: `Bearer ${token}` },
              params: {
                bucket: bucketName,
                property_name: selectedProperty,
                floor_type: selectedFloor,
                floor_name: selectedPit,
                table_sid: fetchedTableSID,
              },
              cancelToken: fetchCancelSource.current.token,
            }
          );

          const { manifest, tempManifest, manifestOff, tempManifestOff } = manifestResponse.data;

          // Function to process and store manifest data
          const processManifest = async (manifestContent, tab) => {
            if (manifestContent) {
              const manifestFilesList = Object.values(manifestContent).flat();

              // Fetch friendly names for manifest files
              const friendlyNames = await fetchFriendlyNames(manifestFilesList);

              const manifestWithFriendlyNames = manifestFilesList.map((file, index) => ({
                file,
                friendlyName: friendlyNames[index],
              }));

              setManifestFiles((prev) => ({ ...prev, [tab]: manifestWithFriendlyNames }));
            } else {
              console.log(`No manifest data found for ${tab}.`);
              setManifestFiles((prev) => ({ ...prev, [tab]: [] }));
            }
          };

          // Process both manifests
          await processManifest(tempManifest || manifest, 'Online');
          await processManifest(tempManifestOff || manifestOff, 'Offline');

          // Step 5: Fetch Media Files for the New Table
          await fetchMediaFiles(fetchedTableSID);
        } else {
          console.log('No SID found for the selected table.');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled:', error.message);
        } else {
          console.error('Error handling table selection:', error);
          toast.error('Failed to handle table selection. Please try again.');
        }
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [
      currentUser,
      customerId,
      selectedProperty,
      selectedFloor,
      selectedPit,
      bucketName,
      fetchFriendlyNames,
      fetchMediaFiles,
      activeManifestTab,
      activeButton,
    ]
  );

  // Cleanup on Component Unmount
  useEffect(() => {
    return () => {
      if (fetchCancelSource.current) {
        fetchCancelSource.current.cancel('Component unmounted.');
      }
    };
  }, []);

  // Fetch Media Files When tableSID Changes
  useEffect(() => {
    if (tableSID) {
      fetchMediaFiles();
    } else {
      setMediaFiles([]); // Clear media files if no table is selected
    }
  }, [tableSID, fetchMediaFiles]);

  // Handle Upload Button Click
  const handleUploadClick = useCallback(() => {
    setIsUploadOverlayVisible(true);
  }, []);

  // Handle Upload Overlay Close
  const handleOverlayClose = useCallback(() => {
    setIsUploadOverlayVisible(false);
    handleMediaClick(activeButton);
  }, [activeButton, handleMediaClick]);

  // Handle Folder Selection
  const handleFolderClick = useCallback(
    (folder) => {
      if (selectedFolder === folder) {
        // If the same folder is clicked again, deselect it
        setSelectedFolder('');
        handleMediaClick(activeButton);
      } else {
        setSelectedFolder(folder);
        fetchMediaFiles(tableSID, folder); // Apply folder filter
      }
    },
    [selectedFolder, fetchMediaFiles, tableSID, activeButton, handleMediaClick]
  );

  // **Handle Adding a File to Manifest (Staging)**
  const handleAddFile = useCallback(
    (fileObj) => {
      const { file } = fileObj;
      const tab = activeManifestTab;
      const currentStagedAdditions = stagedAdditions[tab];
      const currentStagedRemovals = stagedRemovals[tab];

      // Prevent duplicate additions
      if (currentStagedAdditions.find((item) => item.file === file)) {
        alert('This file is already staged for addition.');
        return;
      }

      // If the file was previously staged for removal, cancel the removal
      if (currentStagedRemovals.find((item) => item.file === file)) {
        setStagedRemovals((prev) => ({
          ...prev,
          [tab]: prev[tab].filter((item) => item.file !== file),
        }));
        return;
      }

      // Stage the addition with both `file` and `friendlyName`
      setStagedAdditions((prev) => ({
        ...prev,
        [tab]: [...prev[tab], { file: fileObj.file, friendlyName: fileObj.friendlyName }],
      }));
      setMediaFiles((prev) => prev.filter((item) => item.file !== file));

      console.log(`Added file: ${file}, activeManifestTab: ${tab}`);

      // Set isOrderChanged to true since a change has been made
      setIsOrderChanged(true);
    },
    [stagedAdditions, stagedRemovals, activeManifestTab]
  );

  // **Handle Removing a File from Manifest (Staging)**
  const handleRemoveFile = useCallback(
    (file) => {
      const tab = activeManifestTab;
      const currentManifestFiles = manifestFiles[tab];
      const currentStagedAdditions = stagedAdditions[tab];
      const currentStagedRemovals = stagedRemovals[tab];

      // Find the file object from combined manifest files
      const allFiles = [
        ...currentManifestFiles.map((fileObj) => ({ ...fileObj, status: 'inManifest' })),
        ...currentStagedAdditions.map((fileObj) => ({ ...fileObj, status: 'stagedAddition' })),
      ];

      const fileObj = allFiles.find((item) => item.file === file);
      if (!fileObj) return;

      if (fileObj.status === 'inManifest') {
        // Stage the removal
        if (currentStagedRemovals.find((item) => item.file === file)) {
          alert('This file is already staged for removal.');
          return;
        }

        setStagedRemovals((prev) => ({
          ...prev,
          [tab]: [...prev[tab], fileObj],
        }));
        setManifestFiles((prev) => ({
          ...prev,
          [tab]: prev[tab].filter((item) => item.file !== file),
        }));
      } else if (fileObj.status === 'stagedAddition') {
        // Revert the addition
        setStagedAdditions((prev) => ({
          ...prev,
          [tab]: prev[tab].filter((item) => item.file !== file),
        }));
        setMediaFiles((prev) => [...prev, fileObj]);
      }

      // Set isOrderChanged to true since a change has been made
      setIsOrderChanged(true);
    },
    [manifestFiles, stagedAdditions, stagedRemovals, activeManifestTab]
  );

  // **Handle Reverting a Staged Removal**
  const handleRevertRemoval = useCallback(
    (fileObj) => {
      const tab = activeManifestTab;
      setStagedRemovals((prev) => ({
        ...prev,
        [tab]: prev[tab].filter((item) => item.file !== fileObj.file),
      }));
      setManifestFiles((prev) => ({
        ...prev,
        [tab]: [...prev[tab], fileObj],
      }));

      // Set isOrderChanged to true since a change has been made
      setIsOrderChanged(true);
    },
    [activeManifestTab]
  );

  // **Memoize filteredMediaFiles based on activeButton**
  const filteredMediaFiles = useMemo(() => {
    return mediaFiles.filter(
      (fileObj) =>
        activeButton === MEDIA_TYPES.IMAGES
          ? fileObj.file.toLowerCase().endsWith('.jpg') ||
            fileObj.file.toLowerCase().endsWith('.png') ||
            fileObj.file.toLowerCase().endsWith('.jpeg') // Adjust extensions as needed
          : fileObj.file.toLowerCase().endsWith('.mp4') ||
            fileObj.file.toLowerCase().endsWith('.mov') ||
            fileObj.file.toLowerCase().endsWith('.avi') // Adjust extensions as needed
    );
  }, [mediaFiles, activeButton]);

  // **Debounced Search Term**
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // **Filter Functions**

  // Filtered Media Files Based on Debounced Search Term and Manifest/Removals/Staged Additions
  const filteredAvailableFiles = useMemo(() => {
    const tab = activeManifestTab;
    return filteredMediaFiles
      .filter((fileObj) => {
        const isInManifest = manifestFiles[tab].some(
          (manifestFile) => manifestFile.file === fileObj.file
        );
        const isStagedRemoval = stagedRemovals[tab].some(
          (removal) => removal.file === fileObj.file
        );
        const isStagedAddition = stagedAdditions[tab].some(
          (addition) => addition.file === fileObj.file
        );
        return !isInManifest && !isStagedRemoval && !isStagedAddition;
      })
      .filter(
        (fileObj) =>
          fileObj.file.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          fileObj.friendlyName.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
  }, [
    filteredMediaFiles,
    manifestFiles,
    stagedRemovals,
    stagedAdditions,
    debouncedSearchTerm,
    activeManifestTab,
  ]);

  const filteredStagedRemovals = useMemo(() => {
    const tab = activeManifestTab;
    return stagedRemovals[tab].filter(
      (fileObj) =>
        fileObj.file.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        fileObj.friendlyName.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [stagedRemovals, debouncedSearchTerm, activeManifestTab]);

  const displayedManifestFiles = useMemo(() => {
    const tab = activeManifestTab;
    return [
      ...manifestFiles[tab].map((fileObj) => ({ ...fileObj, status: 'inManifest' })),
      ...stagedAdditions[tab].map((fileObj) => ({ ...fileObj, status: 'stagedAddition' })),
    ];
  }, [manifestFiles, stagedAdditions, activeManifestTab]);

  // **Handle Drag End Event for Drag and Drop**
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(displayedManifestFiles);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const tab = activeManifestTab;

      // Split items back into manifestFiles and stagedAdditions
      const newManifestFiles = [];
      const newStagedAdditions = [];
      items.forEach(({ status, ...rest }) => {
        if (status === 'inManifest') {
          newManifestFiles.push(rest);
        } else if (status === 'stagedAddition') {
          newStagedAdditions.push(rest);
        }
      });

      // Check if the order has changed
      const originalOrder = [...manifestFiles[tab], ...stagedAdditions[tab]];
      const newOrder = [...newManifestFiles, ...newStagedAdditions];

      const hasOrderChanged = !originalOrder.every(
        (item, index) => item.file === newOrder[index].file
      );

      setManifestFiles((prev) => ({ ...prev, [tab]: newManifestFiles }));
      setStagedAdditions((prev) => ({ ...prev, [tab]: newStagedAdditions }));

      if (hasOrderChanged) {
        setIsOrderChanged(true);
      } else {
        setIsOrderChanged(false);
      }
    },
    [displayedManifestFiles, manifestFiles, stagedAdditions, activeManifestTab]
  );

  // **Handle Commit Action**
  const handleCommit = useCallback(
    async () => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        console.log('Committing changes, activeButton:', activeButton);
        const token = await currentUser.getIdToken(true);

        const tab = activeManifestTab;
        const isOffline = tab === 'Offline'; // Determine if working on Offline manifest
        const targetManifest = isOffline ? 'manifestOff.yaml' : 'manifest.yaml'; // Target manifest file

        // **Process Additions**
        for (const addition of stagedAdditions[tab]) {
          const key = getKeyForFile(addition.file); // Determine key per file
          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/manifest/update',
            {
              bucket: bucketName,
              property_name: selectedProperty,
              floor_type: selectedFloor,
              floor_name: selectedPit,
              table_sid: tableSID,
              action: 'add',
              key: key, // Use the dynamically determined key
              file: addition.file, // Use obfuscated name only
              targetManifest, // Specify target manifest file
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }

        // **Process Removals**
        for (const removal of stagedRemovals[tab]) {
          const key = getKeyForFile(removal.file); // Determine key per file
          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/manifest/update',
            {
              bucket: bucketName,
              property_name: selectedProperty,
              floor_type: selectedFloor,
              floor_name: selectedPit,
              table_sid: tableSID,
              action: 'remove',
              key: key, // Use the dynamically determined key
              file: removal.file, // Use obfuscated name only
              targetManifest, // Specify target manifest file
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }

        // **Update the Order of Manifest Files**
        const filesByKey = displayedManifestFiles.reduce((acc, fileObj) => {
          const key = getKeyForFile(fileObj.file);
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(fileObj.file);
          return acc;
        }, {});

        // Make separate API calls for each key to update order
        for (const [key, orderedFiles] of Object.entries(filesByKey)) {
          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/manifest/updateOrder',
            {
              bucket: bucketName,
              property_name: selectedProperty,
              floor_type: selectedFloor,
              floor_name: selectedPit,
              table_sid: tableSID,
              key: key,
              orderedFiles: orderedFiles,
              targetManifest, // Specify target manifest file
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }

        // **Commit the Manifest**
        const commitResponse = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/commitManifest',
          {
            propertyName: selectedProperty,
            floorType: selectedFloor,
            floorName: selectedPit,
            tableSid: tableSID,
            bucket: bucketName,
            targetManifest, // Specify target manifest file
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // **Fetch the Updated Manifest**
        if (commitResponse.status === 200) {
          const manifestResponse = await axios.get(
            'https://project-download-upload.wn.r.appspot.com/api/manifest',
            {
              headers: { Authorization: `Bearer ${token}` },
              params: {
                bucket: bucketName,
                property_name: selectedProperty,
                floor_type: selectedFloor,
                floor_name: selectedPit,
                table_sid: tableSID,
              },
            }
          );

          const { manifest, tempManifest, manifestOff, tempManifestOff } = manifestResponse.data;

          // Function to process and store manifest data
          const processManifest = async (manifestContent, tab) => {
            if (manifestContent) {
              const manifestFilesList = Object.values(manifestContent).flat();

              // Fetch friendly names for manifest files
              const friendlyNames = await fetchFriendlyNames(manifestFilesList);

              const manifestWithFriendlyNames = manifestFilesList.map((file, index) => ({
                file,
                friendlyName: friendlyNames[index],
              }));

              setManifestFiles((prev) => ({ ...prev, [tab]: manifestWithFriendlyNames }));
            } else {
              console.log(`No manifest data found for ${tab}.`);
              setManifestFiles((prev) => ({ ...prev, [tab]: [] }));
            }
          };

          // Update the manifests
          await processManifest(tempManifest || manifest, 'Online');
          await processManifest(tempManifestOff || manifestOff, 'Offline');

          // **Reset isOrderChanged**
          setIsOrderChanged(false);

          // **Clear Staged Changes for Active Tab**
          setStagedAdditions((prev) => ({ ...prev, [tab]: [] }));
          setStagedRemovals((prev) => ({ ...prev, [tab]: [] }));

          // **Refresh Media Files**
          await fetchMediaFiles(tableSID);

          // **Provide Visual Feedback via Toast**
          toast.success('Changes committed successfully!');
        } else {
          toast.error('Failed to commit changes.');
        }
      } catch (error) {
        console.error('Error committing manifest:', error);
        toast.error('Failed to commit changes. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [
      currentUser,
      selectedProperty,
      selectedFloor,
      selectedPit,
      tableSID,
      bucketName,
      displayedManifestFiles,
      stagedAdditions,
      stagedRemovals,
      fetchFriendlyNames,
      fetchMediaFiles,
      activeManifestTab,
      activeButton,
    ]
  );

  // Handle Preview Modal Close
  const handleClosePreview = useCallback(() => {
    setIsPreviewVisible(false);
    setPreviewFileUrl(null);
  }, []);

  // Handle Preview Click
  const handlePreviewClick = useCallback(
    async (file) => {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      setIsLoading(true); // Start loading
      try {
        const token = await currentUser.getIdToken(true);

        // Determine the media type directory based on the active page
        const formattedFileName = decodeURIComponent(file);
        const filePath = `Media/${formattedFileName}`;

        // Request a signed URL from the backend with the adjusted path
        const response = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/imagePreview-signed-url',
          { fileName: filePath },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const signedUrl = response.data.signedUrl;
        if (signedUrl) {
          setPreviewFileUrl(signedUrl);
          setIsPreviewVisible(true);
        } else {
          console.error('No signed URL returned from the backend');
          toast.error('Failed to fetch preview. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching signed URL:', error);
        toast.error('Failed to fetch preview. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [currentUser]
  );

  // **Return Statement**
  return (
    <div className="content-manager-container">
      {/* **Collapsible Sidebar** */}
      <CollapsibleSidebar
        isContainerCollapsed={isContainerCollapsed}
        setIsContainerCollapsed={setIsContainerCollapsed}
        isPropertyCollapsed={isPropertyCollapsed}
        setIsPropertyCollapsed={setIsPropertyCollapsed}
        selectedProperty={selectedProperty}
        handlePropertyChange={handlePropertyChange}
        properties={properties}
        isFloorCollapsed={isFloorCollapsed}
        setIsFloorCollapsed={setIsFloorCollapsed}
        selectedFloor={selectedFloor}
        handleFloorChange={handleFloorChange}
        floors={floors}
        isPitCollapsed={isPitCollapsed}
        setIsPitCollapsed={setIsPitCollapsed}
        selectedPit={selectedPit}
        handlePitChange={handlePitChange}
        pits={pits}
        selectedTable={selectedTable}
        handleTableChange={handleTableChange}
        tables={tables}
        isFolderCollapsed={isFolderCollapsed}
        setIsFolderCollapsed={setIsFolderCollapsed}
        selectedFolder={selectedFolder}
        handleFolderClick={handleFolderClick}
        folders={folders}
      />

      {/* **Main Content Manager Area** */}
      <div className={`content-manager ${isContainerCollapsed ? 'collapsed' : ''}`}>
        {/* **Buttons and Search Bar** */}
        <ButtonsAndSearchBar
          handleImagesClick={() => handleMediaClick(MEDIA_TYPES.IMAGES)}
          handleVideosClick={() => handleMediaClick(MEDIA_TYPES.VIDEOS)}
          activeButton={activeButton}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleUploadClick={handleUploadClick}
          handleCommit={handleCommit}
          isOrderChanged={isOrderChanged}
          // **Pass canUpload prop based on user role**
          canUpload={!isRolesLoading && userRoles.includes('Media Manager')}
        />

        {/* **Loading Indicator Overlay** */}
        <LoadingIndicator isLoading={isLoading} />

        {/* **Available and Manifest Files Columns** */}
        <div className="content-manager-multi-column">
          {/* Available Files Section */}
          <AvailableFilesList
            isTableSelected={Boolean(selectedTable)}
            filteredAvailableFiles={filteredAvailableFiles}
            handleAddFile={handleAddFile}
            handlePreviewClick={handlePreviewClick}
          />

          {/* Manifest Files Section */}
          <div className="content-manager-column manifest-files">
            {/* Manifest Tabs */}
            <div className="manifest-tabs">
              <h3>Manifest Files</h3>
              <div>
                <button
                  className={activeManifestTab === 'Online' ? 'active-tab' : ''}
                  onClick={() => handleManifestTabClick('Online')}
                >
                  Online
                </button>
                <button
                  className={activeManifestTab === 'Offline' ? 'active-tab' : ''}
                  onClick={() => handleManifestTabClick('Offline')}
                >
                  Offline
                </button>
              </div>
            </div>

            {/* Manifest Files Content */}
            <div className="manifest-content">
              {selectedTable ? (
                displayedManifestFiles.length > 0 ? (
                  <ManifestFilesList
                    displayedManifestFiles={displayedManifestFiles}
                    onDragEnd={onDragEnd}
                    handleRemoveFile={handleRemoveFile}
                    handlePreviewClick={handlePreviewClick}
                  />
                ) : (
                  <p>No files in the {activeManifestTab.toLowerCase()} manifest.</p>
                )
              ) : (
                <p>Select a table to load the manifest.</p>
              )}
            </div>

            {/* Staged Remove Files Section */}
            <div className="staged-remove-files-section">
              <h4>Staged: Remove Files From Manifest</h4>
              {filteredStagedRemovals.length === 0 ? (
                <p className="no-staged-removals">No files staged for removal.</p>
              ) : (
                filteredStagedRemovals.map((fileObj) => (
                  <div
                    key={fileObj.file}
                    className="option-item removed"
                    onClick={() => handleRevertRemoval(fileObj)}
                  >
                    {fileObj.friendlyName || fileObj.file}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* **Upload Overlay** */}
        {isUploadOverlayVisible && (
          <div className="upload-overlay">
            <div className="upload-overlay-content">
              <button
                className="close-overlay-button"
                onClick={handleOverlayClose}
                aria-label="Close upload overlay"
              >
                X
              </button>
              <UploadMedia email={currentUser?.email} setSubHeader={() => {}} />
            </div>
          </div>
        )}

        {/* **Toast Container for Notifications** */}
        <ToastContainer />

        {/* **Preview Modal** */}
        {isPreviewVisible && (
          <ImagePreview fileUrl={previewFileUrl} onClose={handleClosePreview} />
        )}
      </div>
    </div>
  );
};

export default ContentManager;
