// Monitoring.js
import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosInstance';
import './Monitoring.css';

const Monitoring = () => {
  const [properties, setProperties] = useState([]);
  const [floors, setFloors] = useState([]);
  const [tables, setTables] = useState([]);
  const [tableStatus, setTableStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Handle icon clicks (for demonstration purposes).
   * @param {string} item - The name of the item clicked.
   */
  const handleIconClick = (item) => {
    console.log(`${item} clicked`);
  };

  /**
   * Fetch all properties associated with the authenticated customer.
   */
  const fetchProperties = async () => {
    try {
      const response = await axiosInstance.get('/api/properties');
      console.log('PROPERTIES response data:', response.data);
      setProperties(response.data);
    } catch (err) {
      console.error('Error fetching properties:', err);
      setError('Failed to load properties');
    }
  };

  /**
   * Fetch all floors associated with the authenticated customer.
   */
  const fetchAllFloors = async () => {
    try {
      const response = await axiosInstance.get('/api/allFloors');
      console.log('ALL FLOORS response data:', response.data);
      setFloors(response.data);
    } catch (err) {
      console.error('Error fetching all floors:', err);
      setError('Failed to load floors');
    }
  };

  /**
   * Fetch all tables associated with the authenticated customer.
   */
  const fetchAllTables = async () => {
    try {
      const response = await axiosInstance.get('/api/allTables');
      console.log('ALL TABLES response data:', response.data);
      setTables(response.data);
    } catch (err) {
      console.error('Error fetching all tables:', err);
      setError('Failed to load tables');
    }
  };

  /**
   * Fetch real-time status for tables (is_online).
   */
  const fetchTableStatus = async () => {
    try {
      const response = await axiosInstance.get('/api/tableStatus');
      console.log('TABLE STATUS response data:', response.data);
      setTableStatus(response.data);
    } catch (err) {
      console.error('Error fetching table status:', err);
      // Optionally, set an error state or notification here
    }
  };

  /**
   * On component mount, fetch properties, floors, tables, and table status.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Fetch properties
        await fetchProperties();

        // 2. Fetch floors
        await fetchAllFloors();

        // 3. Fetch tables
        await fetchAllTables();

        // 4. Fetch table status
        await fetchTableStatus();

        setLoading(false);
      } catch (err) {
        console.error('Error initializing data:', err);
        setError('Failed to load initial data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  /**
   * Set up polling for table status every minute once initial load is complete.
   */
  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(() => {
        fetchTableStatus();
      }, 60000); // 1 minute

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  /**
   * Create a map for quick lookup of table statuses.
   * @returns {object} - Mapping of table_id to is_online status.
   */
  const tableStatusMap = useMemo(() => {
    const map = {};
    tableStatus.forEach((ts) => {
      map[ts.table_id] = ts.is_online;
    });
    return map;
  }, [tableStatus]);

  /**
   * Compute property statuses based on their associated tables' statuses.
   * @returns {object} - Mapping of property_id to status ('online', 'offline', 'amber', 'no-tables')
   */
  const propertyStatuses = useMemo(() => {
    const statusMap = {};

    properties.forEach((property) => {
      // Find all tables for this property
      const propertyTables = tables.filter((t) => t.property_id === property.property_id);

      if (propertyTables.length === 0) {
        statusMap[property.property_id] = 'no-tables';
        return;
      }

      // Determine statuses of tables using tableStatusMap
      const onlineTables = propertyTables.filter((t) => tableStatusMap[t.table_id]).length;
      const totalTables = propertyTables.length;

      if (onlineTables === totalTables) {
        statusMap[property.property_id] = 'online';
      } else if (onlineTables === 0) {
        statusMap[property.property_id] = 'offline';
      } else {
        statusMap[property.property_id] = 'amber';
      }
    });

    return statusMap;
  }, [properties, tables, tableStatusMap]); // Included 'tableStatusMap' in dependencies

  /**
   * Compute company status based on all properties' statuses.
   * - All 'online' => 'online'
   * - All 'offline' => 'offline'
   * - Otherwise => 'amber'
   */
  const companyStatus = useMemo(() => {
    if (properties.length === 0) {
      return 'no-tables'; // Optionally handle no properties scenario
    }

    const statuses = properties.map((property) => propertyStatuses[property.property_id]);

    // If any property has 'no-tables', treat the company status as 'amber'
    if (statuses.includes('no-tables')) {
      return 'amber';
    }

    const allOnline = statuses.every((status) => status === 'online');
    const allOffline = statuses.every((status) => status === 'offline');

    if (allOnline) return 'online';
    if (allOffline) return 'offline';
    return 'amber';
  }, [properties, propertyStatuses]);

  /**
   * Render loading or error states.
   */
  if (loading) {
    return <div className="monitoring-container">Loading data...</div>;
  }

  if (error) {
    return <div className="monitoring-container">{error}</div>;
  }

  return (
    <div className="monitoring-container">
      {/* Top Section: Logo and Company Icon */}
      <div className="monitoring-section top-section">

        {/* Company Icon */}
        <img
          src="/monitoring/logo.png" // Using logo.png as the company icon
          alt="Company"
          className={`monitoring-icon company-icon ${companyStatus}`}
          onClick={() => console.log(`Company (${companyStatus}) clicked`)}
          title={`Company Status: ${companyStatus.charAt(0).toUpperCase() + companyStatus.slice(1)}`}
        />
      </div>

      {/* Middle Section: Properties */}
      <div className="monitoring-section middle-section">
        {properties.map((property) => (
          <img
            key={property.property_id}
            src="/monitoring/property.png"
            alt={property.property_name}
            className={`monitoring-icon ${propertyStatuses[property.property_id]}`}
            onClick={() => handleIconClick(property.property_name)}
            title={`Property: ${property.property_name} (${propertyStatuses[property.property_id].charAt(0).toUpperCase() + propertyStatuses[property.property_id].slice(1)})`}
          />
        ))}
      </div>

      {/* Floors Section */}
      <div className="monitoring-section floors-section">
        {floors.map((floor) => {
          // Find all tables for this floor
          const floorTables = tables.filter((t) => t.floor_id === floor.floor_id);

          if (floorTables.length === 0) {
            return (
              <img
                key={floor.floor_id}
                src="/monitoring/floors.png"
                alt={`${floor.floor_type} - ${floor.floor_name}`}
                className="monitoring-icon no-tables"
                onClick={() => console.log(`${floor.floor_type} - ${floor.floor_name}`)}
                title={`${floor.floor_type} - ${floor.floor_name} (No Tables)`}
              />
            );
          }

          // Determine floor status using tableStatusMap
          const onlineTables = floorTables.filter((t) => tableStatusMap[t.table_id]).length;
          const totalTables = floorTables.length;
          let floorStatus = 'amber'; // Default

          if (onlineTables === totalTables) {
            floorStatus = 'online';
          } else if (onlineTables === 0) {
            floorStatus = 'offline';
          }

          return (
            <img
              key={floor.floor_id}
              src="/monitoring/floors.png"
              alt={`${floor.floor_type} - ${floor.floor_name}`}
              className={`monitoring-icon ${floorStatus}`}
              onClick={() => console.log(`${floor.floor_type} - ${floor.floor_name}`)}
              title={`${floor.floor_type} - ${floor.floor_name} (${floorStatus.charAt(0).toUpperCase() + floorStatus.slice(1)})`}
            />
          );
        })}
      </div>

      {/* Tables Section */}
      <div className="monitoring-section tables-section">
        {tables.map((table) => {
          const online = tableStatusMap[table.table_id];
          return (
            <div key={table.table_id} className="table-item">
              <img
                src="/monitoring/table.png"
                alt={table.table_name}
                className={`monitoring-icon ${online ? 'online' : 'offline'}`}
                onClick={() => console.log(`${table.table_name} - ${table.assigned_screen}`)}
              />
              <div className="table-label">
                {table.table_name} ({online ? 'Online' : 'Offline'})
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Monitoring;
